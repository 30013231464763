// ../../react-shim.js
import React from "react";

// src/image.tsx
import {
  chakra,
  forwardRef
} from "@chakra-ui/system";
import { omit, __DEV__ } from "@chakra-ui/utils";

// src/use-image.ts
import { useSafeLayoutEffect } from "@chakra-ui/hooks";
import { useCallback, useEffect, useRef, useState } from "react";
function useImage(props) {
  const {
    loading,
    src,
    srcSet,
    onLoad,
    onError,
    crossOrigin,
    sizes,
    ignoreFallback
  } = props;
  const [status, setStatus] = useState("pending");
  useEffect(() => {
    setStatus(src ? "loading" : "pending");
  }, [src]);
  const imageRef = useRef();
  const load = useCallback(() => {
    if (!src)
      return;
    flush();
    const img = new Image();
    img.src = src;
    if (crossOrigin)
      img.crossOrigin = crossOrigin;
    if (srcSet)
      img.srcset = srcSet;
    if (sizes)
      img.sizes = sizes;
    if (loading)
      img.loading = loading;
    img.onload = (event) => {
      flush();
      setStatus("loaded");
      onLoad == null ? void 0 : onLoad(event);
    };
    img.onerror = (error) => {
      flush();
      setStatus("failed");
      onError == null ? void 0 : onError(error);
    };
    imageRef.current = img;
  }, [src, crossOrigin, srcSet, sizes, onLoad, onError, loading]);
  const flush = () => {
    if (imageRef.current) {
      imageRef.current.onload = null;
      imageRef.current.onerror = null;
      imageRef.current = null;
    }
  };
  useSafeLayoutEffect(() => {
    if (ignoreFallback)
      return void 0;
    if (status === "loading") {
      load();
    }
    return () => {
      flush();
    };
  }, [status, load, ignoreFallback]);
  return ignoreFallback ? "loaded" : status;
}
var shouldShowFallbackImage = (status, fallbackStrategy) => status !== "loaded" && fallbackStrategy === "beforeLoadOrError" || status === "failed" && fallbackStrategy === "onError";

// src/image.tsx
var NativeImage = forwardRef(function NativeImage2(props, ref) {
  const { htmlWidth, htmlHeight, alt, ...rest } = props;
  return /* @__PURE__ */ React.createElement("img", {
    width: htmlWidth,
    height: htmlHeight,
    ref,
    alt,
    ...rest
  });
});
if (__DEV__) {
  NativeImage.displayName = "NativeImage";
}
var Image2 = forwardRef(function Image3(props, ref) {
  const {
    fallbackSrc,
    fallback,
    src,
    srcSet,
    align,
    fit,
    loading,
    ignoreFallback,
    crossOrigin,
    fallbackStrategy = "beforeLoadOrError",
    referrerPolicy,
    ...rest
  } = props;
  const providedFallback = fallbackSrc !== void 0 || fallback !== void 0;
  const shouldIgnoreFallbackImage = loading != null || ignoreFallback || !providedFallback;
  const status = useImage({
    ...props,
    ignoreFallback: shouldIgnoreFallbackImage
  });
  const showFallbackImage = shouldShowFallbackImage(status, fallbackStrategy);
  const shared = {
    ref,
    objectFit: fit,
    objectPosition: align,
    ...shouldIgnoreFallbackImage ? rest : omit(rest, ["onError", "onLoad"])
  };
  if (showFallbackImage) {
    if (fallback)
      return fallback;
    return /* @__PURE__ */ React.createElement(chakra.img, {
      as: NativeImage,
      className: "chakra-image__placeholder",
      src: fallbackSrc,
      ...shared
    });
  }
  return /* @__PURE__ */ React.createElement(chakra.img, {
    as: NativeImage,
    src,
    srcSet,
    crossOrigin,
    loading,
    referrerPolicy,
    className: "chakra-image",
    ...shared
  });
});
var Img = forwardRef((props, ref) => /* @__PURE__ */ React.createElement(chakra.img, {
  ref,
  as: NativeImage,
  className: "chakra-image",
  ...props
}));
if (__DEV__) {
  Image2.displayName = "Image";
}
export {
  Image2 as Image,
  Img,
  shouldShowFallbackImage,
  useImage
};
