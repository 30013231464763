/* eslint-disable no-console */
import {
  walletSdkEvents,
  WalletConnection,
  WalletSDK,
  WalletManager,
  WALLET_SDK_EVENTS,
} from '@imtbl/wallet-sdk-web';
import { useEffect, useState } from 'react';
import { ImxClientConfig } from './ImxClientProvider';

async function getChainName(walletConnection: WalletConnection): Promise<string> {
  const activeChainName = (await walletConnection.l1Signer.provider?.getNetwork())?.name;
  if (!activeChainName) {
    return '';
  }
  return activeChainName === 'homestead' ? 'mainnet' : activeChainName;
}

export function useImxClientManager(config: ImxClientConfig) {
  const {
    walletSDKConfig,
  } = config;

  const [walletSDK, setWalletsSDK] = useState<WalletSDK | undefined>(undefined);
  const [walletManager, setWalletsManager] = useState<WalletManager | undefined>(undefined);
  const [walletConnection, setWalletConnection] =
    useState<WalletConnection | null>(null);
  const [ethAddress, setEthAddress] = useState('');
  const [starkAddress, setStarkAddress] = useState('');
  const [chain, setChain] = useState('');

  useEffect(() => {
    const buildSDK = async () => {
      const sdk = await WalletSDK.build(walletSDKConfig);
      const manager = new WalletManager();

      setWalletsSDK(sdk);
      setWalletsManager(manager);
    };

    buildSDK().catch(console.error);
  }, []);

  useEffect(() => {
    walletSdkEvents.on(WALLET_SDK_EVENTS.WALLET_DISCONNECTED, () => {
      console.log(`[sample-app] event ${WALLET_SDK_EVENTS.WALLET_DISCONNECTED}`);
      setWalletConnection(null);
    });
    walletSdkEvents.on(
      WALLET_SDK_EVENTS.CONNECTION_UPDATED,
      async (connection: WalletConnection) => {
        console.log(`[sample-app] event ${WALLET_SDK_EVENTS.CONNECTION_UPDATED}`);
        setWalletConnection(connection);
      },
    );
  }, [walletSdkEvents]);

  useEffect(() => {
    (async () => {
      if (walletConnection) {
        setEthAddress(await walletConnection.l1Signer.getAddress());
        setStarkAddress(await walletConnection.l2Signer.getAddress());
        const activeChain = await getChainName(walletConnection);
        setChain(activeChain);
      }
    })();
  }, [walletConnection]);

  return {
    chain,
    ethAddress,
    starkAddress,
    walletConnection,
    walletSDK,
    walletManager,
  };
}
