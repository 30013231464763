import { ENVIRONMENTS, IRPCMap } from '@imtbl/wallet-sdk-web';
import React from 'react';

import { ImxClientContext } from './ImxClientContext';
import { useImxClientManager } from './ImxClientManager';

export type WalletSDKConfig = {
  env: ENVIRONMENTS;
  rpc: IRPCMap;
  chainID?: number;
}

export type CoreSDKConfig = {
  chainID: number;
  api: string;
  coreContract: string;
  registrationContract: string;
}

export interface ImxClientConfig {
  walletSDKConfig: WalletSDKConfig;
  coreSDKConfig: CoreSDKConfig;
}

interface ImxClientProviderPropTypes {
  children: JSX.Element | JSX.Element[];
  config: ImxClientConfig;
}

export const ImxClientProvider = ({
  children,
  config,
}: ImxClientProviderPropTypes) => {
  const imxContext = useImxClientManager(config);
  return (
    <ImxClientContext.Provider value={imxContext}>
      {children}
    </ImxClientContext.Provider>
  );
};
