import { WalletConnection, WalletSDK, WalletManager } from '@imtbl/wallet-sdk-web';
import { createContext, useContext } from 'react';

interface ImxClientContextInterface {
  chain?: string;
  ethAddress?: string;
  starkAddress?: string;
  walletConnection: WalletConnection | null;
  walletSDK?: WalletSDK;
  walletManager?: WalletManager;
}

export const ImxClientContext = createContext<ImxClientContextInterface>(
  {} as ImxClientContextInterface,
);

ImxClientContext.displayName = 'ImxClientContext';

export const useImxClientContext = () => useContext<ImxClientContextInterface>(ImxClientContext);
