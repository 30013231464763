import { ImxClientConfig } from './context/ImxClientProvider';
import { walletConnectConfig } from './walletConnectConfig';

const GOERLI_CHAIN_ID = 5;
const chainID = Number(process.env.CONNECTION_CHAIN_ID) || GOERLI_CHAIN_ID;

export const imxClientConfig = {
  walletSDKConfig: {
    env: process.env.ENV_NAME || 'development',
    chainID,
    ...walletConnectConfig,
  },
  coreSDKConfig: {
    chainID,
    api: process.env.IMX_PUBLIC_API_URL || 'https://api.sandbox.x.immutable.com',
    coreContract: process.env.CORE_CONTRACT_ADDRESS || '0x4527BE8f31E2ebFbEF4fCADDb5a17447B27d2aef',
    registrationContract: process.env.REGISTRATION_CONTRACT_ADDRESS || '0x6C21EC8DE44AE44D0992ec3e2d9f1aBb6207D864',
  },
} as ImxClientConfig;
