// ../../react-shim.js
import React from "react";

// src/collapse.tsx
import { cx, mergeWith, warn, __DEV__ } from "@chakra-ui/utils";
import {
  AnimatePresence,
  motion
} from "framer-motion";
import { forwardRef, useEffect, useState } from "react";

// src/transition-utils.ts
var TransitionEasings = {
  ease: [0.25, 0.1, 0.25, 1],
  easeIn: [0.4, 0, 1, 1],
  easeOut: [0, 0, 0.2, 1],
  easeInOut: [0.4, 0, 0.2, 1]
};
var TransitionVariants = {
  scale: {
    enter: { scale: 1 },
    exit: { scale: 0.95 }
  },
  fade: {
    enter: { opacity: 1 },
    exit: { opacity: 0 }
  },
  pushLeft: {
    enter: { x: "100%" },
    exit: { x: "-30%" }
  },
  pushRight: {
    enter: { x: "-100%" },
    exit: { x: "30%" }
  },
  pushUp: {
    enter: { y: "100%" },
    exit: { y: "-30%" }
  },
  pushDown: {
    enter: { y: "-100%" },
    exit: { y: "30%" }
  },
  slideLeft: {
    position: { left: 0, top: 0, bottom: 0, width: "100%" },
    enter: { x: 0, y: 0 },
    exit: { x: "-100%", y: 0 }
  },
  slideRight: {
    position: { right: 0, top: 0, bottom: 0, width: "100%" },
    enter: { x: 0, y: 0 },
    exit: { x: "100%", y: 0 }
  },
  slideUp: {
    position: { top: 0, left: 0, right: 0, maxWidth: "100vw" },
    enter: { x: 0, y: 0 },
    exit: { x: 0, y: "-100%" }
  },
  slideDown: {
    position: { bottom: 0, left: 0, right: 0, maxWidth: "100vw" },
    enter: { x: 0, y: 0 },
    exit: { x: 0, y: "100%" }
  }
};
function slideTransition(options) {
  const side = (options == null ? void 0 : options.direction) ?? "right";
  switch (side) {
    case "right":
      return TransitionVariants.slideRight;
    case "left":
      return TransitionVariants.slideLeft;
    case "bottom":
      return TransitionVariants.slideDown;
    case "top":
      return TransitionVariants.slideUp;
    default:
      return TransitionVariants.slideRight;
  }
}
var TransitionDefaults = {
  enter: {
    duration: 0.2,
    ease: TransitionEasings.easeOut
  },
  exit: {
    duration: 0.1,
    ease: TransitionEasings.easeIn
  }
};
var withDelay = {
  enter: (transition, delay) => ({
    ...transition,
    delay: typeof delay === "number" ? delay : delay == null ? void 0 : delay["enter"]
  }),
  exit: (transition, delay) => ({
    ...transition,
    delay: typeof delay === "number" ? delay : delay == null ? void 0 : delay["exit"]
  })
};

// src/collapse.tsx
var isNumeric = (value) => value != null && parseInt(value.toString(), 10) > 0;
var defaultTransitions = {
  exit: {
    height: { duration: 0.2, ease: TransitionEasings.ease },
    opacity: { duration: 0.3, ease: TransitionEasings.ease }
  },
  enter: {
    height: { duration: 0.3, ease: TransitionEasings.ease },
    opacity: { duration: 0.4, ease: TransitionEasings.ease }
  }
};
var variants = {
  exit: ({
    animateOpacity,
    startingHeight,
    transition,
    transitionEnd,
    delay
  }) => ({
    ...animateOpacity && { opacity: isNumeric(startingHeight) ? 1 : 0 },
    height: startingHeight,
    transitionEnd: transitionEnd == null ? void 0 : transitionEnd.exit,
    transition: (transition == null ? void 0 : transition.exit) ?? withDelay.exit(defaultTransitions.exit, delay)
  }),
  enter: ({
    animateOpacity,
    endingHeight,
    transition,
    transitionEnd,
    delay
  }) => ({
    ...animateOpacity && { opacity: 1 },
    height: endingHeight,
    transitionEnd: transitionEnd == null ? void 0 : transitionEnd.enter,
    transition: (transition == null ? void 0 : transition.enter) ?? withDelay.enter(defaultTransitions.enter, delay)
  })
};
var Collapse = forwardRef((props, ref) => {
  const {
    in: isOpen,
    unmountOnExit,
    animateOpacity = true,
    startingHeight = 0,
    endingHeight = "auto",
    style,
    className,
    transition,
    transitionEnd,
    ...rest
  } = props;
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setMounted(true);
    });
    return () => clearTimeout(timeout);
  }, []);
  warn({
    condition: Boolean(startingHeight > 0 && unmountOnExit),
    message: `startingHeight and unmountOnExit are mutually exclusive. You can't use them together`
  });
  const hasStartingHeight = parseFloat(startingHeight.toString()) > 0;
  const custom = {
    startingHeight,
    endingHeight,
    animateOpacity,
    transition: !mounted ? { enter: { duration: 0 } } : transition,
    transitionEnd: mergeWith(transitionEnd, {
      exit: unmountOnExit ? void 0 : {
        display: hasStartingHeight ? "block" : "none"
      }
    })
  };
  const show = unmountOnExit ? isOpen : true;
  const animate = isOpen || unmountOnExit ? "enter" : "exit";
  return /* @__PURE__ */ React.createElement(AnimatePresence, {
    initial: false,
    custom
  }, show && /* @__PURE__ */ React.createElement(motion.div, {
    ref,
    ...rest,
    className: cx("chakra-collapse", className),
    style: {
      overflow: "hidden",
      display: "block",
      ...style
    },
    custom,
    variants,
    initial: unmountOnExit ? "exit" : false,
    animate,
    exit: "exit"
  }));
});
if (__DEV__) {
  Collapse.displayName = "Collapse";
}

// src/fade.tsx
import { cx as cx2, __DEV__ as __DEV__2 } from "@chakra-ui/utils";
import {
  AnimatePresence as AnimatePresence2,
  motion as motion2
} from "framer-motion";
import { forwardRef as forwardRef2 } from "react";
var variants2 = {
  enter: ({ transition, transitionEnd, delay } = {}) => ({
    opacity: 1,
    transition: (transition == null ? void 0 : transition.enter) ?? withDelay.enter(TransitionDefaults.enter, delay),
    transitionEnd: transitionEnd == null ? void 0 : transitionEnd.enter
  }),
  exit: ({ transition, transitionEnd, delay } = {}) => ({
    opacity: 0,
    transition: (transition == null ? void 0 : transition.exit) ?? withDelay.exit(TransitionDefaults.exit, delay),
    transitionEnd: transitionEnd == null ? void 0 : transitionEnd.exit
  })
};
var fadeConfig = {
  initial: "exit",
  animate: "enter",
  exit: "exit",
  variants: variants2
};
var Fade = forwardRef2(function Fade2(props, ref) {
  const {
    unmountOnExit,
    in: isOpen,
    className,
    transition,
    transitionEnd,
    delay,
    ...rest
  } = props;
  const animate = isOpen || unmountOnExit ? "enter" : "exit";
  const show = unmountOnExit ? isOpen && unmountOnExit : true;
  const custom = { transition, transitionEnd, delay };
  return /* @__PURE__ */ React.createElement(AnimatePresence2, {
    custom
  }, show && /* @__PURE__ */ React.createElement(motion2.div, {
    ref,
    className: cx2("chakra-fade", className),
    custom,
    ...fadeConfig,
    animate,
    ...rest
  }));
});
if (__DEV__2) {
  Fade.displayName = "Fade";
}

// src/scale-fade.tsx
import { cx as cx3, __DEV__ as __DEV__3 } from "@chakra-ui/utils";
import {
  AnimatePresence as AnimatePresence3,
  motion as motion3
} from "framer-motion";
import { forwardRef as forwardRef3 } from "react";
var variants3 = {
  exit: ({ reverse, initialScale, transition, transitionEnd, delay }) => ({
    opacity: 0,
    ...reverse ? { scale: initialScale, transitionEnd: transitionEnd == null ? void 0 : transitionEnd.exit } : { transitionEnd: { scale: initialScale, ...transitionEnd == null ? void 0 : transitionEnd.exit } },
    transition: (transition == null ? void 0 : transition.exit) ?? withDelay.exit(TransitionDefaults.exit, delay)
  }),
  enter: ({ transitionEnd, transition, delay }) => ({
    opacity: 1,
    scale: 1,
    transition: (transition == null ? void 0 : transition.enter) ?? withDelay.enter(TransitionDefaults.enter, delay),
    transitionEnd: transitionEnd == null ? void 0 : transitionEnd.enter
  })
};
var scaleFadeConfig = {
  initial: "exit",
  animate: "enter",
  exit: "exit",
  variants: variants3
};
var ScaleFade = forwardRef3(function ScaleFade2(props, ref) {
  const {
    unmountOnExit,
    in: isOpen,
    reverse = true,
    initialScale = 0.95,
    className,
    transition,
    transitionEnd,
    delay,
    ...rest
  } = props;
  const show = unmountOnExit ? isOpen && unmountOnExit : true;
  const animate = isOpen || unmountOnExit ? "enter" : "exit";
  const custom = { initialScale, reverse, transition, transitionEnd, delay };
  return /* @__PURE__ */ React.createElement(AnimatePresence3, {
    custom
  }, show && /* @__PURE__ */ React.createElement(motion3.div, {
    ref,
    className: cx3("chakra-offset-slide", className),
    ...scaleFadeConfig,
    animate,
    custom,
    ...rest
  }));
});
if (__DEV__3) {
  ScaleFade.displayName = "ScaleFade";
}

// src/slide.tsx
import { cx as cx4, __DEV__ as __DEV__4 } from "@chakra-ui/utils";
import {
  AnimatePresence as AnimatePresence4,
  motion as motion4
} from "framer-motion";
import { forwardRef as forwardRef4 } from "react";
var defaultTransition = {
  exit: {
    duration: 0.15,
    ease: TransitionEasings.easeInOut
  },
  enter: {
    type: "spring",
    damping: 25,
    stiffness: 180
  }
};
var variants4 = {
  exit: ({ direction, transition, transitionEnd, delay }) => {
    const { exit: exitStyles } = slideTransition({ direction });
    return {
      ...exitStyles,
      transition: (transition == null ? void 0 : transition.exit) ?? withDelay.exit(defaultTransition.exit, delay),
      transitionEnd: transitionEnd == null ? void 0 : transitionEnd.exit
    };
  },
  enter: ({ direction, transitionEnd, transition, delay }) => {
    const { enter: enterStyles } = slideTransition({ direction });
    return {
      ...enterStyles,
      transition: (transition == null ? void 0 : transition.enter) ?? withDelay.enter(defaultTransition.enter, delay),
      transitionEnd: transitionEnd == null ? void 0 : transitionEnd.enter
    };
  }
};
var Slide = forwardRef4(function Slide2(props, ref) {
  const {
    direction = "right",
    style,
    unmountOnExit,
    in: isOpen,
    className,
    transition,
    transitionEnd,
    delay,
    ...rest
  } = props;
  const transitionStyles = slideTransition({ direction });
  const computedStyle = Object.assign({ position: "fixed" }, transitionStyles.position, style);
  const show = unmountOnExit ? isOpen && unmountOnExit : true;
  const animate = isOpen || unmountOnExit ? "enter" : "exit";
  const custom = { transitionEnd, transition, direction, delay };
  return /* @__PURE__ */ React.createElement(AnimatePresence4, {
    custom
  }, show && /* @__PURE__ */ React.createElement(motion4.div, {
    ...rest,
    ref,
    initial: "exit",
    className: cx4("chakra-slide", className),
    animate,
    exit: "exit",
    custom,
    variants: variants4,
    style: computedStyle
  }));
});
if (__DEV__4) {
  Slide.displayName = "Slide";
}

// src/slide-fade.tsx
import { cx as cx5, __DEV__ as __DEV__5 } from "@chakra-ui/utils";
import {
  AnimatePresence as AnimatePresence5,
  motion as motion5
} from "framer-motion";
import { forwardRef as forwardRef5 } from "react";
var variants5 = {
  initial: ({ offsetX, offsetY, transition, transitionEnd, delay }) => ({
    opacity: 0,
    x: offsetX,
    y: offsetY,
    transition: (transition == null ? void 0 : transition.exit) ?? withDelay.exit(TransitionDefaults.exit, delay),
    transitionEnd: transitionEnd == null ? void 0 : transitionEnd.exit
  }),
  enter: ({ transition, transitionEnd, delay }) => ({
    opacity: 1,
    x: 0,
    y: 0,
    transition: (transition == null ? void 0 : transition.enter) ?? withDelay.enter(TransitionDefaults.enter, delay),
    transitionEnd: transitionEnd == null ? void 0 : transitionEnd.enter
  }),
  exit: ({ offsetY, offsetX, transition, transitionEnd, reverse, delay }) => {
    const offset = { x: offsetX, y: offsetY };
    return {
      opacity: 0,
      transition: (transition == null ? void 0 : transition.exit) ?? withDelay.exit(TransitionDefaults.exit, delay),
      ...reverse ? { ...offset, transitionEnd: transitionEnd == null ? void 0 : transitionEnd.exit } : { transitionEnd: { ...offset, ...transitionEnd == null ? void 0 : transitionEnd.exit } }
    };
  }
};
var slideFadeConfig = {
  initial: "initial",
  animate: "enter",
  exit: "exit",
  variants: variants5
};
var SlideFade = forwardRef5(function SlideFade2(props, ref) {
  const {
    unmountOnExit,
    in: isOpen,
    reverse = true,
    className,
    offsetX = 0,
    offsetY = 8,
    transition,
    transitionEnd,
    delay,
    ...rest
  } = props;
  const show = unmountOnExit ? isOpen && unmountOnExit : true;
  const animate = isOpen || unmountOnExit ? "enter" : "exit";
  const custom = {
    offsetX,
    offsetY,
    reverse,
    transition,
    transitionEnd,
    delay
  };
  return /* @__PURE__ */ React.createElement(AnimatePresence5, {
    custom
  }, show && /* @__PURE__ */ React.createElement(motion5.div, {
    ref,
    className: cx5("chakra-offset-slide", className),
    custom,
    ...slideFadeConfig,
    animate,
    ...rest
  }));
});
if (__DEV__5) {
  SlideFade.displayName = "SlideFade";
}
export {
  Collapse,
  TransitionEasings as EASINGS,
  Fade,
  ScaleFade,
  Slide,
  SlideFade,
  fadeConfig,
  scaleFadeConfig,
  slideFadeConfig
};
