// ../../react-shim.js
import React from "react";

// src/index.ts
export * from "@chakra-ui/accordion";
export * from "@chakra-ui/alert";
export * from "@chakra-ui/avatar";
export * from "@chakra-ui/breadcrumb";
export * from "@chakra-ui/button";
export * from "@chakra-ui/checkbox";
export * from "@chakra-ui/close-button";
export * from "@chakra-ui/control-box";
export * from "@chakra-ui/counter";
export * from "@chakra-ui/css-reset";
export * from "@chakra-ui/editable";
export * from "@chakra-ui/form-control";
export * from "@chakra-ui/hooks";
export * from "@chakra-ui/icon";
export * from "@chakra-ui/image";
export * from "@chakra-ui/input";
export * from "@chakra-ui/layout";
export * from "@chakra-ui/media-query";
export * from "@chakra-ui/menu";
export * from "@chakra-ui/modal";
export * from "@chakra-ui/number-input";
export * from "@chakra-ui/pin-input";
export * from "@chakra-ui/popover";
export * from "@chakra-ui/popper";
export * from "@chakra-ui/portal";
export * from "@chakra-ui/progress";
export * from "@chakra-ui/radio";
export * from "@chakra-ui/react-env";
export * from "@chakra-ui/select";
export * from "@chakra-ui/skeleton";
export * from "@chakra-ui/slider";
export * from "@chakra-ui/spinner";
export * from "@chakra-ui/stat";
export * from "@chakra-ui/switch";
export * from "@chakra-ui/system";
export * from "@chakra-ui/table";
export * from "@chakra-ui/tabs";
export * from "@chakra-ui/tag";
export * from "@chakra-ui/textarea";
export * from "@chakra-ui/theme";
export * from "@chakra-ui/toast";
export * from "@chakra-ui/tooltip";
export * from "@chakra-ui/transition";
export * from "@chakra-ui/visually-hidden";

// src/chakra-provider.tsx
import {
  ChakraProvider as BaseChakraProvider
} from "@chakra-ui/provider";
import { theme as defaultTheme } from "@chakra-ui/theme";
import { ToastProvider } from "@chakra-ui/toast";
function ChakraProvider({
  children,
  theme: theme2 = defaultTheme,
  toastOptions,
  ...restProps
}) {
  return /* @__PURE__ */ React.createElement(BaseChakraProvider, {
    theme: theme2,
    ...restProps
  }, children, /* @__PURE__ */ React.createElement(ToastProvider, {
    ...toastOptions
  }));
}

// src/extend-theme.ts
import { theme, isChakraTheme } from "@chakra-ui/theme";
import {
  isFunction,
  mergeWith,
  pipe
} from "@chakra-ui/utils";
function extendTheme(...extensions) {
  let overrides = [...extensions];
  let baseTheme = extensions[extensions.length - 1];
  if (isChakraTheme(baseTheme) && overrides.length > 1) {
    overrides = overrides.slice(0, overrides.length - 1);
  } else {
    baseTheme = theme;
  }
  return pipe(...overrides.map((extension) => (prevTheme) => isFunction(extension) ? extension(prevTheme) : mergeThemeOverride(prevTheme, extension)))(baseTheme);
}
function mergeThemeOverride(...overrides) {
  return mergeWith({}, ...overrides, mergeThemeCustomizer);
}
function mergeThemeCustomizer(source, override, key, object) {
  if ((isFunction(source) || isFunction(override)) && Object.prototype.hasOwnProperty.call(object, key)) {
    return (...args) => {
      const sourceValue = isFunction(source) ? source(...args) : source;
      const overrideValue = isFunction(override) ? override(...args) : override;
      return mergeWith({}, sourceValue, overrideValue, mergeThemeCustomizer);
    };
  }
  return void 0;
}

// src/theme-extensions/with-default-color-scheme.ts
import { isObject, fromEntries } from "@chakra-ui/utils";
function withDefaultColorScheme({
  colorScheme,
  components
}) {
  return (theme2) => {
    let names = Object.keys(theme2.components || {});
    if (Array.isArray(components)) {
      names = components;
    } else if (isObject(components)) {
      names = Object.keys(components);
    }
    return mergeThemeOverride(theme2, {
      components: fromEntries(names.map((componentName) => {
        const withColorScheme = {
          defaultProps: {
            colorScheme
          }
        };
        return [componentName, withColorScheme];
      }))
    });
  };
}

// src/theme-extensions/with-default-size.ts
import { isObject as isObject2, fromEntries as fromEntries2 } from "@chakra-ui/utils";
function withDefaultSize({
  size,
  components
}) {
  return (theme2) => {
    let names = Object.keys(theme2.components || {});
    if (Array.isArray(components)) {
      names = components;
    } else if (isObject2(components)) {
      names = Object.keys(components);
    }
    return mergeThemeOverride(theme2, {
      components: fromEntries2(names.map((componentName) => {
        const withSize = {
          defaultProps: {
            size
          }
        };
        return [componentName, withSize];
      }))
    });
  };
}

// src/theme-extensions/with-default-variant.ts
import { isObject as isObject3, fromEntries as fromEntries3 } from "@chakra-ui/utils";
function withDefaultVariant({
  variant,
  components
}) {
  return (theme2) => {
    let names = Object.keys(theme2.components || {});
    if (Array.isArray(components)) {
      names = components;
    } else if (isObject3(components)) {
      names = Object.keys(components);
    }
    return mergeThemeOverride(theme2, {
      components: fromEntries3(names.map((componentName) => {
        const withVariant = {
          defaultProps: {
            variant
          }
        };
        return [componentName, withVariant];
      }))
    });
  };
}

// src/theme-extensions/with-default-props.ts
import { pipe as pipe2 } from "@chakra-ui/utils";
function withDefaultProps({
  defaultProps: { colorScheme, variant, size },
  components
}) {
  const identity = (t) => t;
  const fns = [
    colorScheme ? withDefaultColorScheme({ colorScheme, components }) : identity,
    size ? withDefaultSize({ size, components }) : identity,
    variant ? withDefaultVariant({ variant, components }) : identity
  ];
  return (theme2) => mergeThemeOverride(pipe2(...fns)(theme2));
}
export {
  ChakraProvider,
  extendTheme,
  mergeThemeOverride,
  withDefaultColorScheme,
  withDefaultProps,
  withDefaultSize,
  withDefaultVariant
};
