// ../../react-shim.js
import React from "react";

// src/portal-manager.tsx
import { __DEV__ } from "@chakra-ui/utils";
import { createContext } from "@chakra-ui/react-utils";
var [PortalManagerContextProvider, usePortalManager] = createContext({
  strict: false,
  name: "PortalManagerContext"
});
function PortalManager(props) {
  const { children, zIndex } = props;
  return /* @__PURE__ */ React.createElement(PortalManagerContextProvider, {
    value: { zIndex }
  }, children);
}
if (__DEV__) {
  PortalManager.displayName = "PortalManager";
}

// src/portal.tsx
import { useForceUpdate, useSafeLayoutEffect } from "@chakra-ui/hooks";
import { isBrowser, __DEV__ as __DEV__2 } from "@chakra-ui/utils";
import { createContext as createContext2 } from "@chakra-ui/react-utils";
import { createPortal } from "react-dom";
import { useEffect, useMemo, useRef, useState } from "react";
var [PortalContextProvider, usePortalContext] = createContext2({
  strict: false,
  name: "PortalContext"
});
var PORTAL_CLASSNAME = "chakra-portal";
var PORTAL_SELECTOR = `.chakra-portal`;
var Container = (props) => /* @__PURE__ */ React.createElement("div", {
  className: "chakra-portal-zIndex",
  style: {
    position: "absolute",
    zIndex: props.zIndex,
    top: 0,
    left: 0,
    right: 0
  }
}, props.children);
var DefaultPortal = (props) => {
  const { appendToParentPortal, children } = props;
  const [tempNode, setTempNode] = useState(null);
  const portal = useRef(null);
  const forceUpdate = useForceUpdate();
  useEffect(forceUpdate, []);
  const parentPortal = usePortalContext();
  const manager = usePortalManager();
  useSafeLayoutEffect(() => {
    if (!tempNode)
      return;
    const doc = tempNode.ownerDocument;
    const host = appendToParentPortal ? parentPortal ?? doc.body : doc.body;
    if (!host)
      return;
    portal.current = doc.createElement("div");
    portal.current.className = PORTAL_CLASSNAME;
    host.appendChild(portal.current);
    forceUpdate();
    const portalNode = portal.current;
    return () => {
      if (host.contains(portalNode)) {
        host.removeChild(portalNode);
      }
    };
  }, [tempNode]);
  const _children = (manager == null ? void 0 : manager.zIndex) ? /* @__PURE__ */ React.createElement(Container, {
    zIndex: manager == null ? void 0 : manager.zIndex
  }, children) : children;
  return portal.current ? createPortal(/* @__PURE__ */ React.createElement(PortalContextProvider, {
    value: portal.current
  }, _children), portal.current) : /* @__PURE__ */ React.createElement("span", {
    ref: (el) => {
      if (el)
        setTempNode(el);
    }
  });
};
var ContainerPortal = (props) => {
  const { children, containerRef, appendToParentPortal } = props;
  const containerEl = containerRef.current;
  const host = containerEl ?? (isBrowser ? document.body : void 0);
  const portal = useMemo(() => {
    const node = containerEl == null ? void 0 : containerEl.ownerDocument.createElement("div");
    if (node)
      node.className = PORTAL_CLASSNAME;
    return node;
  }, [containerEl]);
  const forceUpdate = useForceUpdate();
  useSafeLayoutEffect(() => {
    forceUpdate();
  }, []);
  useSafeLayoutEffect(() => {
    if (!portal || !host)
      return;
    host.appendChild(portal);
    return () => {
      host.removeChild(portal);
    };
  }, [portal, host]);
  if (host && portal) {
    return createPortal(/* @__PURE__ */ React.createElement(PortalContextProvider, {
      value: appendToParentPortal ? portal : null
    }, children), portal);
  }
  return null;
};
function Portal(props) {
  const { containerRef, ...rest } = props;
  return containerRef ? /* @__PURE__ */ React.createElement(ContainerPortal, {
    containerRef,
    ...rest
  }) : /* @__PURE__ */ React.createElement(DefaultPortal, {
    ...rest
  });
}
Portal.defaultProps = {
  appendToParentPortal: true
};
Portal.className = PORTAL_CLASSNAME;
Portal.selector = PORTAL_SELECTOR;
if (__DEV__2) {
  Portal.displayName = "Portal";
}
export {
  Portal,
  PortalManager,
  usePortalManager
};
