import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { imxClientConfig } from './ImxClientConfig';
import { ImxClientProvider } from './context/ImxClientProvider';

const container = document.getElementById('app');
if (container) {
  const root = createRoot(container);
  root.render(
    <ChakraProvider>
      <ImxClientProvider config={imxClientConfig}>
        <App />
      </ImxClientProvider>
    </ChakraProvider>,
  );
} else {
  throw new Error('The element with "app" id does not exist');
}
