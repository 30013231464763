import {
  Heading,
  Flex,
  Spacer,
  Box,
  Text,
  Badge,
} from '@chakra-ui/react';
import React from 'react';
import { useImxClientContext } from '../context/ImxClientContext';
import { WalletManager } from './WalletManager';
import { WalletMenu } from './WalletMenu';

const genShorterEtherAddress = (ethAddress: string) => {
  const addressLength = ethAddress?.length;
  return (addressLength < 8) ? ethAddress : `${ethAddress?.slice(0, 4)}....${ethAddress?.slice(addressLength - 4, addressLength)}`;
};

const WalletAddressView = ({ ethAddress }: { ethAddress: string }) => {
  const address = genShorterEtherAddress(ethAddress);
  return <Text as='samp' fontSize='lg'> {address}</Text>;
};

export function Header() {
  const {
    walletConnection, chain, starkAddress, ethAddress,
  } = useImxClientContext();
  return (
    <Flex
      as="nav"
      minWidth='max-content'
      align="center"
      position='fixed'
      justify="space-between"
      backgroundColor="rgba(255, 255, 255, 0.8)"
      backdropFilter="saturate(180%) blur(5px)"
      w='100%'
      gap='2'
      style={{ zIndex: '200' }}>
      <Box p='4'>
        <Heading size='md'>Wallet SDK Sample App</Heading>
      </Box>
      <Spacer />
      {walletConnection && (
        <>
          <Flex>
            <Box><Badge colorScheme='green'>{chain}</Badge></Box>
            <Box pl='2'>{ethAddress && <WalletAddressView ethAddress={ethAddress} />}</Box>
          </Flex>
          <div style={{ display: 'none' }}>Stark Address: {starkAddress}</div>
        </>
      )}
      <WalletMenu />
      <WalletManager />
    </Flex>
  );
}
