/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import './App.css';
import { Header } from './components/Header';
import { useImxClientContext } from './context/ImxClientContext';

enum AppStatus {
  DISCONNECTED = 'DISCONNECTED',
  CONNECTING = 'CONNECTING',
  CONNECTED = 'CONNECTED',
}

export function App() {
  const {
    walletConnection,
  } = useImxClientContext();

  const [status, setStatus] = useState<AppStatus>(AppStatus.DISCONNECTED);

  useEffect(
    () =>
      setStatus(walletConnection ? AppStatus.CONNECTED : AppStatus.DISCONNECTED),
    [walletConnection],
  );

  return (
    <div>
      <Header />
      <div style={{ paddingTop: '80px' }}>
        <h3
          style={{
            color: status === AppStatus.CONNECTED ? 'green' : 'default',
          }}
        >
          {`Wallet is ${status}!`}
        </h3>
      </div>
    </div>
  );
}
