import {
  useDisclosure,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  Box,
  Flex,
  Img,
} from '@chakra-ui/react';
import {
  ImmutableProvider,
} from '@imtbl/provider-sdk-web';
import React from 'react';
import { useImxClientContext } from '../context/ImxClientContext';

export function WalletManager() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    walletManager,
  } = useImxClientContext();
  const providers = walletManager && walletManager.getAvailableWallets();

  return (
    <Box p='4'>
      <Button
        aria-label='Options'
        variant='outline' onClick={onOpen}>Wallet Manager</Button>

      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose Wallet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column">
              { providers && providers.map((provider: ImmutableProvider) => (
                <Button
                  leftIcon={<Img src={provider.providerIcon} h="22px" w='22px'/>}
                  colorScheme='gray'
                  mb="10px"
                  onClick={
                    async () => {
                      await provider.connect({ chainID: 5 }).then(() => onClose());
                    }
                  }
                >
                  {provider.providerName}
                </Button>
              ))
              }
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
