import { HamburgerIcon } from '@chakra-ui/icons';
import {
  useDisclosure,
  Button,
  IconButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  Box,
  Flex,
} from '@chakra-ui/react';
import { ConnectParams, L1_PROVIDERS } from '@imtbl/wallet-sdk-web';
import React, { useCallback } from 'react';
import { useImxClientContext } from '../context/ImxClientContext';
import { MetamaskIcon, WalletConnectIcon } from './WalletIcons';

export function WalletMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { walletConnection, walletSDK } = useImxClientContext();

  const connect = useCallback(
    async (params: ConnectParams) => {
      if (!walletSDK) {
        return;
      }
      await walletSDK.connect(params);
      onClose();
    },
    [walletSDK],
  );

  const disconnect = useCallback(async (): Promise<void> => {
    if (!walletSDK) {
      return;
    }
    await walletSDK.disconnect();
  }, [walletSDK]);

  return (
    <Box p='4'>
      {!walletConnection &&
        <>
          <Button as={IconButton}
            aria-label='Options'
            icon={<HamburgerIcon />}
            variant='outline' onClick={onOpen}>Open Modal</Button>

          <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Choose Wallet</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex direction="column">
                  <Button
                    leftIcon={<MetamaskIcon />}
                    colorScheme='gray'
                    onClick={() =>
                      connect({ provider: L1_PROVIDERS.METAMASK })
                    }
                  >
                    Metamask
                  </Button>
                  <Button
                    leftIcon={<WalletConnectIcon />}
                    colorScheme='gray'
                    onClick={ () =>
                      connect({ provider: L1_PROVIDERS.WALLET_CONNECT })
                    }
                  >
                    WallectConnect
                  </Button>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      }
      {walletConnection &&
        <Button onClick={disconnect}>Disconnect</Button>
      }
    </Box>
  );
}
