// ../../react-shim.js
import React from "react";

// src/modal.tsx
import { CloseButton } from "@chakra-ui/close-button";
import { FocusLock } from "@chakra-ui/focus-lock";
import { Portal } from "@chakra-ui/portal";
import { createContext } from "@chakra-ui/react-utils";
import {
  chakra as chakra2,
  forwardRef as forwardRef2,
  useMultiStyleConfig
} from "@chakra-ui/system";
import { fadeConfig } from "@chakra-ui/transition";
import {
  callAllHandlers as callAllHandlers2,
  cx,
  __DEV__ as __DEV__2
} from "@chakra-ui/utils";
import {
  AnimatePresence,
  motion as motion2,
  usePresence
} from "framer-motion";
import { useEffect as useEffect3 } from "react";
import { RemoveScroll } from "react-remove-scroll";

// src/modal-transition.tsx
import { chakra } from "@chakra-ui/system";
import { scaleFadeConfig, slideFadeConfig } from "@chakra-ui/transition";
import { __DEV__ } from "@chakra-ui/utils";
import { motion } from "framer-motion";
import { forwardRef } from "react";
var transitions = {
  slideInBottom: {
    ...slideFadeConfig,
    custom: { offsetY: 16, reverse: true }
  },
  slideInRight: {
    ...slideFadeConfig,
    custom: { offsetX: 16, reverse: true }
  },
  scale: {
    ...scaleFadeConfig,
    custom: { initialScale: 0.95, reverse: true }
  },
  none: {}
};
var Motion = chakra(motion.section);
var ModalTransition = forwardRef((props, ref) => {
  const { preset, ...rest } = props;
  const motionProps = transitions[preset];
  return /* @__PURE__ */ React.createElement(Motion, {
    ref,
    ...motionProps,
    ...rest
  });
});
if (__DEV__) {
  ModalTransition.displayName = "ModalTransition";
}

// src/use-modal.ts
import { useIds } from "@chakra-ui/hooks";
import { callAllHandlers } from "@chakra-ui/utils";
import { mergeRefs } from "@chakra-ui/react-utils";
import { hideOthers } from "aria-hidden";
import { useCallback, useEffect as useEffect2, useRef, useState } from "react";

// src/modal-manager.ts
import { useEffect } from "react";
var ModalManager = class {
  modals;
  constructor() {
    this.modals = [];
  }
  add(modal) {
    this.modals.push(modal);
  }
  remove(modal) {
    this.modals = this.modals.filter((_modal) => _modal !== modal);
  }
  isTopModal(modal) {
    const topmostModal = this.modals[this.modals.length - 1];
    return topmostModal === modal;
  }
};
var manager = new ModalManager();
function useModalManager(ref, isOpen) {
  useEffect(() => {
    if (isOpen) {
      manager.add(ref);
    }
    return () => {
      manager.remove(ref);
    };
  }, [isOpen, ref]);
}

// src/use-modal.ts
function useModal(props) {
  const {
    isOpen,
    onClose,
    id,
    closeOnOverlayClick = true,
    closeOnEsc = true,
    useInert = true,
    onOverlayClick: onOverlayClickProp,
    onEsc
  } = props;
  const dialogRef = useRef(null);
  const overlayRef = useRef(null);
  const [dialogId, headerId, bodyId] = useIds(id, `chakra-modal`, `chakra-modal--header`, `chakra-modal--body`);
  useAriaHidden(dialogRef, isOpen && useInert);
  useModalManager(dialogRef, isOpen);
  const mouseDownTarget = useRef(null);
  const onMouseDown = useCallback((event) => {
    mouseDownTarget.current = event.target;
  }, []);
  const onKeyDown = useCallback((event) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      if (closeOnEsc) {
        onClose == null ? void 0 : onClose();
      }
      onEsc == null ? void 0 : onEsc();
    }
  }, [closeOnEsc, onClose, onEsc]);
  const [headerMounted, setHeaderMounted] = useState(false);
  const [bodyMounted, setBodyMounted] = useState(false);
  const getDialogProps = useCallback((props2 = {}, ref = null) => ({
    role: "dialog",
    ...props2,
    ref: mergeRefs(ref, dialogRef),
    id: dialogId,
    tabIndex: -1,
    "aria-modal": true,
    "aria-labelledby": headerMounted ? headerId : void 0,
    "aria-describedby": bodyMounted ? bodyId : void 0,
    onClick: callAllHandlers(props2.onClick, (event) => event.stopPropagation())
  }), [bodyId, bodyMounted, dialogId, headerId, headerMounted]);
  const onOverlayClick = useCallback((event) => {
    event.stopPropagation();
    if (mouseDownTarget.current !== event.target)
      return;
    if (!manager.isTopModal(dialogRef))
      return;
    if (closeOnOverlayClick) {
      onClose == null ? void 0 : onClose();
    }
    onOverlayClickProp == null ? void 0 : onOverlayClickProp();
  }, [onClose, closeOnOverlayClick, onOverlayClickProp]);
  const getDialogContainerProps = useCallback((props2 = {}, ref = null) => ({
    ...props2,
    ref: mergeRefs(ref, overlayRef),
    onClick: callAllHandlers(props2.onClick, onOverlayClick),
    onKeyDown: callAllHandlers(props2.onKeyDown, onKeyDown),
    onMouseDown: callAllHandlers(props2.onMouseDown, onMouseDown)
  }), [onKeyDown, onMouseDown, onOverlayClick]);
  return {
    isOpen,
    onClose,
    headerId,
    bodyId,
    setBodyMounted,
    setHeaderMounted,
    dialogRef,
    overlayRef,
    getDialogProps,
    getDialogContainerProps
  };
}
function useAriaHidden(ref, shouldHide) {
  const currentElement = ref.current;
  useEffect2(() => {
    if (!ref.current || !shouldHide)
      return void 0;
    return hideOthers(ref.current);
  }, [shouldHide, ref, currentElement]);
}

// src/modal.tsx
var [ModalStylesProvider, useModalStyles] = createContext({
  name: `ModalStylesContext`,
  errorMessage: `useModalStyles returned is 'undefined'. Seems you forgot to wrap the components in "<Modal />" `
});
var [ModalContextProvider, useModalContext] = createContext({
  strict: true,
  name: "ModalContext",
  errorMessage: "useModalContext: `context` is undefined. Seems you forgot to wrap modal components in `<Modal />`"
});
var Modal = (props) => {
  const {
    portalProps,
    children,
    autoFocus,
    trapFocus,
    initialFocusRef,
    finalFocusRef,
    returnFocusOnClose,
    blockScrollOnMount,
    allowPinchZoom,
    preserveScrollBarGap,
    motionPreset,
    lockFocusAcrossFrames,
    onCloseComplete
  } = props;
  const styles = useMultiStyleConfig("Modal", props);
  const modal = useModal(props);
  const context = {
    ...modal,
    autoFocus,
    trapFocus,
    initialFocusRef,
    finalFocusRef,
    returnFocusOnClose,
    blockScrollOnMount,
    allowPinchZoom,
    preserveScrollBarGap,
    motionPreset,
    lockFocusAcrossFrames
  };
  return /* @__PURE__ */ React.createElement(ModalContextProvider, {
    value: context
  }, /* @__PURE__ */ React.createElement(ModalStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(AnimatePresence, {
    onExitComplete: onCloseComplete
  }, context.isOpen && /* @__PURE__ */ React.createElement(Portal, {
    ...portalProps
  }, children))));
};
Modal.defaultProps = {
  lockFocusAcrossFrames: true,
  returnFocusOnClose: true,
  scrollBehavior: "outside",
  trapFocus: true,
  autoFocus: true,
  blockScrollOnMount: true,
  allowPinchZoom: false,
  motionPreset: "scale"
};
if (__DEV__2) {
  Modal.displayName = "Modal";
}
var MotionDiv = chakra2(motion2.div);
var ModalContent = forwardRef2((props, ref) => {
  const { className, children, containerProps: rootProps, ...rest } = props;
  const { getDialogProps, getDialogContainerProps } = useModalContext();
  const dialogProps = getDialogProps(rest, ref);
  const containerProps = getDialogContainerProps(rootProps);
  const _className = cx("chakra-modal__content", className);
  const styles = useModalStyles();
  const dialogStyles = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    outline: 0,
    ...styles.dialog
  };
  const dialogContainerStyles = {
    display: "flex",
    width: "100vw",
    height: "100vh",
    "@supports(height: -webkit-fill-available)": {
      height: "-webkit-fill-available"
    },
    position: "fixed",
    left: 0,
    top: 0,
    ...styles.dialogContainer
  };
  const { motionPreset } = useModalContext();
  return /* @__PURE__ */ React.createElement(ModalFocusScope, null, /* @__PURE__ */ React.createElement(chakra2.div, {
    ...containerProps,
    className: "chakra-modal__content-container",
    tabIndex: -1,
    __css: dialogContainerStyles
  }, /* @__PURE__ */ React.createElement(ModalTransition, {
    preset: motionPreset,
    className: _className,
    ...dialogProps,
    __css: dialogStyles
  }, children)));
});
if (__DEV__2) {
  ModalContent.displayName = "ModalContent";
}
function ModalFocusScope(props) {
  const {
    autoFocus,
    trapFocus,
    dialogRef,
    initialFocusRef,
    blockScrollOnMount,
    allowPinchZoom,
    finalFocusRef,
    returnFocusOnClose,
    preserveScrollBarGap,
    lockFocusAcrossFrames
  } = useModalContext();
  const [isPresent, safeToRemove] = usePresence();
  useEffect3(() => {
    if (!isPresent && safeToRemove) {
      setTimeout(safeToRemove);
    }
  }, [isPresent, safeToRemove]);
  return /* @__PURE__ */ React.createElement(FocusLock, {
    autoFocus,
    isDisabled: !trapFocus,
    initialFocusRef,
    finalFocusRef,
    restoreFocus: returnFocusOnClose,
    contentRef: dialogRef,
    lockFocusAcrossFrames
  }, /* @__PURE__ */ React.createElement(RemoveScroll, {
    removeScrollBar: !preserveScrollBarGap,
    allowPinchZoom,
    enabled: blockScrollOnMount,
    forwardProps: true
  }, props.children));
}
var ModalOverlay = forwardRef2((props, ref) => {
  const { className, transition, ...rest } = props;
  const _className = cx("chakra-modal__overlay", className);
  const styles = useModalStyles();
  const overlayStyle = {
    pos: "fixed",
    left: "0",
    top: "0",
    w: "100vw",
    h: "100vh",
    ...styles.overlay
  };
  const { motionPreset } = useModalContext();
  const motionProps = motionPreset === "none" ? {} : fadeConfig;
  return /* @__PURE__ */ React.createElement(MotionDiv, {
    ...motionProps,
    __css: overlayStyle,
    ref,
    className: _className,
    ...rest
  });
});
if (__DEV__2) {
  ModalOverlay.displayName = "ModalOverlay";
}
var ModalHeader = forwardRef2((props, ref) => {
  const { className, ...rest } = props;
  const { headerId, setHeaderMounted } = useModalContext();
  useEffect3(() => {
    setHeaderMounted(true);
    return () => setHeaderMounted(false);
  }, [setHeaderMounted]);
  const _className = cx("chakra-modal__header", className);
  const styles = useModalStyles();
  const headerStyles = {
    flex: 0,
    ...styles.header
  };
  return /* @__PURE__ */ React.createElement(chakra2.header, {
    ref,
    className: _className,
    id: headerId,
    ...rest,
    __css: headerStyles
  });
});
if (__DEV__2) {
  ModalHeader.displayName = "ModalHeader";
}
var ModalBody = forwardRef2((props, ref) => {
  const { className, ...rest } = props;
  const { bodyId, setBodyMounted } = useModalContext();
  useEffect3(() => {
    setBodyMounted(true);
    return () => setBodyMounted(false);
  }, [setBodyMounted]);
  const _className = cx("chakra-modal__body", className);
  const styles = useModalStyles();
  return /* @__PURE__ */ React.createElement(chakra2.div, {
    ref,
    className: _className,
    id: bodyId,
    ...rest,
    __css: styles.body
  });
});
if (__DEV__2) {
  ModalBody.displayName = "ModalBody";
}
var ModalFooter = forwardRef2((props, ref) => {
  const { className, ...rest } = props;
  const _className = cx("chakra-modal__footer", className);
  const styles = useModalStyles();
  const footerStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ...styles.footer
  };
  return /* @__PURE__ */ React.createElement(chakra2.footer, {
    ref,
    ...rest,
    __css: footerStyles,
    className: _className
  });
});
if (__DEV__2) {
  ModalFooter.displayName = "ModalFooter";
}
var ModalCloseButton = forwardRef2((props, ref) => {
  const { onClick, className, ...rest } = props;
  const { onClose } = useModalContext();
  const _className = cx("chakra-modal__close-btn", className);
  const styles = useModalStyles();
  return /* @__PURE__ */ React.createElement(CloseButton, {
    ref,
    __css: styles.closeButton,
    className: _className,
    onClick: callAllHandlers2(onClick, (event) => {
      event.stopPropagation();
      onClose();
    }),
    ...rest
  });
});
if (__DEV__2) {
  ModalCloseButton.displayName = "ModalCloseButton";
}

// src/alert-dialog.tsx
import { forwardRef as forwardRef3 } from "@chakra-ui/system";
function AlertDialog(props) {
  const { leastDestructiveRef, ...rest } = props;
  return /* @__PURE__ */ React.createElement(Modal, {
    ...rest,
    initialFocusRef: leastDestructiveRef
  });
}
var AlertDialogContent = forwardRef3((props, ref) => /* @__PURE__ */ React.createElement(ModalContent, {
  ref,
  role: "alertdialog",
  ...props
}));

// src/drawer.tsx
import { createContext as createContext2 } from "@chakra-ui/react-utils";
import {
  chakra as chakra3,
  forwardRef as forwardRef4,
  useTheme
} from "@chakra-ui/system";
import { Slide } from "@chakra-ui/transition";
import { cx as cx2, __DEV__ as __DEV__3 } from "@chakra-ui/utils";
var [DrawerContextProvider, useDrawerContext] = createContext2();
var placementMap = {
  start: { ltr: "left", rtl: "right" },
  end: { ltr: "right", rtl: "left" }
};
function getDrawerPlacement(placement, dir) {
  var _a;
  if (!placement)
    return;
  return ((_a = placementMap[placement]) == null ? void 0 : _a[dir]) ?? placement;
}
function Drawer(props) {
  var _a;
  const {
    isOpen,
    onClose,
    placement: placementProp = "right",
    children,
    ...rest
  } = props;
  const theme = useTheme();
  const drawerStyleConfig = (_a = theme.components) == null ? void 0 : _a.Drawer;
  const placement = getDrawerPlacement(placementProp, theme.direction);
  return /* @__PURE__ */ React.createElement(DrawerContextProvider, {
    value: { placement }
  }, /* @__PURE__ */ React.createElement(Modal, {
    isOpen,
    onClose,
    styleConfig: drawerStyleConfig,
    ...rest
  }, children));
}
var StyledSlide = chakra3(Slide);
var DrawerContent = forwardRef4((props, ref) => {
  const { className, children, ...rest } = props;
  const { getDialogProps, getDialogContainerProps, isOpen } = useModalContext();
  const dialogProps = getDialogProps(rest, ref);
  const containerProps = getDialogContainerProps();
  const _className = cx2("chakra-modal__content", className);
  const styles = useModalStyles();
  const dialogStyles = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    outline: 0,
    ...styles.dialog
  };
  const dialogContainerStyles = {
    display: "flex",
    width: "100vw",
    height: "100vh",
    position: "fixed",
    left: 0,
    top: 0,
    ...styles.dialogContainer
  };
  const { placement } = useDrawerContext();
  return /* @__PURE__ */ React.createElement(chakra3.div, {
    ...containerProps,
    className: "chakra-modal__content-container",
    __css: dialogContainerStyles
  }, /* @__PURE__ */ React.createElement(ModalFocusScope, null, /* @__PURE__ */ React.createElement(StyledSlide, {
    direction: placement,
    in: isOpen,
    className: _className,
    ...dialogProps,
    __css: dialogStyles
  }, children)));
});
if (__DEV__3) {
  DrawerContent.displayName = "DrawerContent";
}
export {
  AlertDialog,
  ModalBody as AlertDialogBody,
  ModalCloseButton as AlertDialogCloseButton,
  AlertDialogContent,
  ModalFooter as AlertDialogFooter,
  ModalHeader as AlertDialogHeader,
  ModalOverlay as AlertDialogOverlay,
  Drawer,
  ModalBody as DrawerBody,
  ModalCloseButton as DrawerCloseButton,
  DrawerContent,
  ModalFooter as DrawerFooter,
  ModalHeader as DrawerHeader,
  ModalOverlay as DrawerOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContextProvider,
  ModalFocusScope,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useAriaHidden,
  useDrawerContext,
  useModal,
  useModalContext,
  useModalStyles
};
