// ../../react-shim.js
import React from "react";

// src/chakra-provider.tsx
import CSSReset from "@chakra-ui/css-reset";
import { PortalManager } from "@chakra-ui/portal";
import {
  ColorModeProvider,
  GlobalStyle,
  ThemeProvider
} from "@chakra-ui/system";
import {
  EnvironmentProvider
} from "@chakra-ui/react-env";
var ChakraProvider = (props) => {
  const {
    children,
    colorModeManager,
    portalZIndex,
    resetCSS = true,
    theme = {},
    environment,
    cssVarsRoot
  } = props;
  const _children = /* @__PURE__ */ React.createElement(EnvironmentProvider, {
    environment
  }, children);
  return /* @__PURE__ */ React.createElement(ThemeProvider, {
    theme,
    cssVarsRoot
  }, /* @__PURE__ */ React.createElement(ColorModeProvider, {
    colorModeManager,
    options: theme.config
  }, resetCSS && /* @__PURE__ */ React.createElement(CSSReset, null), /* @__PURE__ */ React.createElement(GlobalStyle, null), portalZIndex ? /* @__PURE__ */ React.createElement(PortalManager, {
    zIndex: portalZIndex
  }, _children) : _children));
};
export {
  ChakraProvider
};
