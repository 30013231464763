// ../../react-shim.js
import React from "react";

// src/visually-hidden.tsx
import { chakra } from "@chakra-ui/system";
import { __DEV__ } from "@chakra-ui/utils";
var visuallyHiddenStyle = {
  border: "0px",
  clip: "rect(0px, 0px, 0px, 0px)",
  height: "1px",
  width: "1px",
  margin: "-1px",
  padding: "0px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  position: "absolute"
};
var VisuallyHidden = chakra("span", {
  baseStyle: visuallyHiddenStyle
});
if (__DEV__) {
  VisuallyHidden.displayName = "VisuallyHidden";
}
var VisuallyHiddenInput = chakra("input", {
  baseStyle: visuallyHiddenStyle
});
if (__DEV__) {
  VisuallyHiddenInput.displayName = "VisuallyHiddenInput";
}
var visually_hidden_default = VisuallyHidden;
export {
  VisuallyHidden,
  VisuallyHiddenInput,
  visually_hidden_default as default,
  visuallyHiddenStyle
};
