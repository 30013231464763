// src/components/accordion.ts
import { accordionAnatomy as parts } from "@chakra-ui/anatomy";
var baseStyleContainer = {
  borderTopWidth: "1px",
  borderColor: "inherit",
  _last: {
    borderBottomWidth: "1px"
  }
};
var baseStyleButton = {
  transitionProperty: "common",
  transitionDuration: "normal",
  fontSize: "1rem",
  _focusVisible: {
    boxShadow: "outline"
  },
  _hover: {
    bg: "blackAlpha.50"
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed"
  },
  px: 4,
  py: 2
};
var baseStylePanel = {
  pt: 2,
  px: 4,
  pb: 5
};
var baseStyleIcon = {
  fontSize: "1.25em"
};
var baseStyle = {
  root: {},
  container: baseStyleContainer,
  button: baseStyleButton,
  panel: baseStylePanel,
  icon: baseStyleIcon
};
var accordion_default = {
  parts: parts.keys,
  baseStyle
};

// src/components/alert.ts
import { alertAnatomy as parts2 } from "@chakra-ui/anatomy";
import { getColor, mode, transparentize } from "@chakra-ui/theme-tools";
var baseStyle2 = {
  container: {
    px: 4,
    py: 3
  },
  title: {
    fontWeight: "bold",
    lineHeight: 6,
    marginEnd: 2
  },
  description: {
    lineHeight: 6
  },
  icon: {
    flexShrink: 0,
    marginEnd: 3,
    w: 5,
    h: 6
  },
  spinner: {
    flexShrink: 0,
    marginEnd: 3,
    w: 5,
    h: 5
  }
};
function getBg(props) {
  const { theme: theme2, colorScheme: c } = props;
  const lightBg = getColor(theme2, `${c}.100`, c);
  const darkBg = transparentize(`${c}.200`, 0.16)(theme2);
  return mode(lightBg, darkBg)(props);
}
var variantSubtle = (props) => {
  const { colorScheme: c } = props;
  return {
    container: { bg: getBg(props) },
    icon: { color: mode(`${c}.500`, `${c}.200`)(props) },
    spinner: {
      color: mode(`${c}.500`, `${c}.200`)(props)
    }
  };
};
var variantLeftAccent = (props) => {
  const { colorScheme: c } = props;
  return {
    container: {
      paddingStart: 3,
      borderStartWidth: "4px",
      borderStartColor: mode(`${c}.500`, `${c}.200`)(props),
      bg: getBg(props)
    },
    icon: {
      color: mode(`${c}.500`, `${c}.200`)(props)
    },
    spinner: {
      color: mode(`${c}.500`, `${c}.200`)(props)
    }
  };
};
var variantTopAccent = (props) => {
  const { colorScheme: c } = props;
  return {
    container: {
      pt: 2,
      borderTopWidth: "4px",
      borderTopColor: mode(`${c}.500`, `${c}.200`)(props),
      bg: getBg(props)
    },
    icon: {
      color: mode(`${c}.500`, `${c}.200`)(props)
    },
    spinner: {
      color: mode(`${c}.500`, `${c}.200`)(props)
    }
  };
};
var variantSolid = (props) => {
  const { colorScheme: c } = props;
  return {
    container: {
      bg: mode(`${c}.500`, `${c}.200`)(props),
      color: mode(`white`, `gray.900`)(props)
    }
  };
};
var variants = {
  subtle: variantSubtle,
  "left-accent": variantLeftAccent,
  "top-accent": variantTopAccent,
  solid: variantSolid
};
var defaultProps = {
  variant: "subtle",
  colorScheme: "blue"
};
var alert_default = {
  parts: parts2.keys,
  baseStyle: baseStyle2,
  variants,
  defaultProps
};

// src/components/avatar.ts
import { avatarAnatomy as parts3 } from "@chakra-ui/anatomy";
import { isDark, mode as mode2, randomColor } from "@chakra-ui/theme-tools";

// src/foundations/spacing.ts
var spacing = {
  px: "1px",
  0.5: "0.125rem",
  1: "0.25rem",
  1.5: "0.375rem",
  2: "0.5rem",
  2.5: "0.625rem",
  3: "0.75rem",
  3.5: "0.875rem",
  4: "1rem",
  5: "1.25rem",
  6: "1.5rem",
  7: "1.75rem",
  8: "2rem",
  9: "2.25rem",
  10: "2.5rem",
  12: "3rem",
  14: "3.5rem",
  16: "4rem",
  20: "5rem",
  24: "6rem",
  28: "7rem",
  32: "8rem",
  36: "9rem",
  40: "10rem",
  44: "11rem",
  48: "12rem",
  52: "13rem",
  56: "14rem",
  60: "15rem",
  64: "16rem",
  72: "18rem",
  80: "20rem",
  96: "24rem"
};

// src/foundations/sizes.ts
var largeSizes = {
  max: "max-content",
  min: "min-content",
  full: "100%",
  "3xs": "14rem",
  "2xs": "16rem",
  xs: "20rem",
  sm: "24rem",
  md: "28rem",
  lg: "32rem",
  xl: "36rem",
  "2xl": "42rem",
  "3xl": "48rem",
  "4xl": "56rem",
  "5xl": "64rem",
  "6xl": "72rem",
  "7xl": "80rem",
  "8xl": "90rem"
};
var container = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px"
};
var sizes = {
  ...spacing,
  ...largeSizes,
  container
};
var sizes_default = sizes;

// src/components/avatar.ts
var baseStyleBadge = (props) => {
  return {
    transform: "translate(25%, 25%)",
    borderRadius: "full",
    border: "0.2em solid",
    borderColor: mode2("white", "gray.800")(props)
  };
};
var baseStyleExcessLabel = (props) => {
  return {
    bg: mode2("gray.200", "whiteAlpha.400")(props)
  };
};
var baseStyleContainer2 = (props) => {
  const { name, theme: theme2 } = props;
  const bg = name ? randomColor({ string: name }) : "gray.400";
  const isBgDark = isDark(bg)(theme2);
  let color = "white";
  if (!isBgDark)
    color = "gray.800";
  const borderColor = mode2("white", "gray.800")(props);
  return {
    bg,
    color,
    borderColor,
    verticalAlign: "top"
  };
};
var baseStyle3 = (props) => ({
  badge: baseStyleBadge(props),
  excessLabel: baseStyleExcessLabel(props),
  container: baseStyleContainer2(props)
});
function getSize(size2) {
  const themeSize = size2 !== "100%" ? sizes_default[size2] : void 0;
  return {
    container: {
      width: size2,
      height: size2,
      fontSize: `calc(${themeSize ?? size2} / 2.5)`
    },
    excessLabel: {
      width: size2,
      height: size2
    },
    label: {
      fontSize: `calc(${themeSize ?? size2} / 2.5)`,
      lineHeight: size2 !== "100%" ? themeSize ?? size2 : void 0
    }
  };
}
var sizes2 = {
  "2xs": getSize(4),
  xs: getSize(6),
  sm: getSize(8),
  md: getSize(12),
  lg: getSize(16),
  xl: getSize(24),
  "2xl": getSize(32),
  full: getSize("100%")
};
var defaultProps2 = {
  size: "md"
};
var avatar_default = {
  parts: parts3.keys,
  baseStyle: baseStyle3,
  sizes: sizes2,
  defaultProps: defaultProps2
};

// src/components/badge.ts
import { getColor as getColor2, mode as mode3, transparentize as transparentize2 } from "@chakra-ui/theme-tools";
var baseStyle4 = {
  px: 1,
  textTransform: "uppercase",
  fontSize: "xs",
  borderRadius: "sm",
  fontWeight: "bold"
};
var variantSolid2 = (props) => {
  const { colorScheme: c, theme: theme2 } = props;
  const dark = transparentize2(`${c}.500`, 0.6)(theme2);
  return {
    bg: mode3(`${c}.500`, dark)(props),
    color: mode3(`white`, `whiteAlpha.800`)(props)
  };
};
var variantSubtle2 = (props) => {
  const { colorScheme: c, theme: theme2 } = props;
  const darkBg = transparentize2(`${c}.200`, 0.16)(theme2);
  return {
    bg: mode3(`${c}.100`, darkBg)(props),
    color: mode3(`${c}.800`, `${c}.200`)(props)
  };
};
var variantOutline = (props) => {
  const { colorScheme: c, theme: theme2 } = props;
  const darkColor = transparentize2(`${c}.200`, 0.8)(theme2);
  const lightColor = getColor2(theme2, `${c}.500`);
  const color = mode3(lightColor, darkColor)(props);
  return {
    color,
    boxShadow: `inset 0 0 0px 1px ${color}`
  };
};
var variants2 = {
  solid: variantSolid2,
  subtle: variantSubtle2,
  outline: variantOutline
};
var defaultProps3 = {
  variant: "subtle",
  colorScheme: "gray"
};
var badge_default = {
  baseStyle: baseStyle4,
  variants: variants2,
  defaultProps: defaultProps3
};

// src/components/breadcrumb.ts
import { breadcrumbAnatomy as parts4 } from "@chakra-ui/anatomy";
var baseStyleLink = {
  transitionProperty: "common",
  transitionDuration: "fast",
  transitionTimingFunction: "ease-out",
  cursor: "pointer",
  textDecoration: "none",
  outline: "none",
  color: "inherit",
  _hover: {
    textDecoration: "underline"
  },
  _focusVisible: {
    boxShadow: "outline"
  }
};
var baseStyle5 = {
  link: baseStyleLink
};
var breadcrumb_default = {
  parts: parts4.keys,
  baseStyle: baseStyle5
};

// src/components/button.ts
import { mode as mode4, transparentize as transparentize3 } from "@chakra-ui/theme-tools";
var baseStyle6 = {
  lineHeight: "1.2",
  borderRadius: "md",
  fontWeight: "semibold",
  transitionProperty: "common",
  transitionDuration: "normal",
  _focusVisible: {
    boxShadow: "outline"
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed",
    boxShadow: "none"
  },
  _hover: {
    _disabled: {
      bg: "initial"
    }
  }
};
var variantGhost = (props) => {
  const { colorScheme: c, theme: theme2 } = props;
  if (c === "gray") {
    return {
      color: mode4(`inherit`, `whiteAlpha.900`)(props),
      _hover: {
        bg: mode4(`gray.100`, `whiteAlpha.200`)(props)
      },
      _active: { bg: mode4(`gray.200`, `whiteAlpha.300`)(props) }
    };
  }
  const darkHoverBg = transparentize3(`${c}.200`, 0.12)(theme2);
  const darkActiveBg = transparentize3(`${c}.200`, 0.24)(theme2);
  return {
    color: mode4(`${c}.600`, `${c}.200`)(props),
    bg: "transparent",
    _hover: {
      bg: mode4(`${c}.50`, darkHoverBg)(props)
    },
    _active: {
      bg: mode4(`${c}.100`, darkActiveBg)(props)
    }
  };
};
var variantOutline2 = (props) => {
  const { colorScheme: c } = props;
  const borderColor = mode4(`gray.200`, `whiteAlpha.300`)(props);
  return {
    border: "1px solid",
    borderColor: c === "gray" ? borderColor : "currentColor",
    ".chakra-button__group[data-attached] > &:not(:last-of-type)": {
      marginEnd: "-1px"
    },
    ...variantGhost(props)
  };
};
var accessibleColorMap = {
  yellow: {
    bg: "yellow.400",
    color: "black",
    hoverBg: "yellow.500",
    activeBg: "yellow.600"
  },
  cyan: {
    bg: "cyan.400",
    color: "black",
    hoverBg: "cyan.500",
    activeBg: "cyan.600"
  }
};
var variantSolid3 = (props) => {
  const { colorScheme: c } = props;
  if (c === "gray") {
    const bg2 = mode4(`gray.100`, `whiteAlpha.200`)(props);
    return {
      bg: bg2,
      _hover: {
        bg: mode4(`gray.200`, `whiteAlpha.300`)(props),
        _disabled: {
          bg: bg2
        }
      },
      _active: { bg: mode4(`gray.300`, `whiteAlpha.400`)(props) }
    };
  }
  const {
    bg = `${c}.500`,
    color = "white",
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`
  } = accessibleColorMap[c] ?? {};
  const background = mode4(bg, `${c}.200`)(props);
  return {
    bg: background,
    color: mode4(color, `gray.800`)(props),
    _hover: {
      bg: mode4(hoverBg, `${c}.300`)(props),
      _disabled: {
        bg: background
      }
    },
    _active: { bg: mode4(activeBg, `${c}.400`)(props) }
  };
};
var variantLink = (props) => {
  const { colorScheme: c } = props;
  return {
    padding: 0,
    height: "auto",
    lineHeight: "normal",
    verticalAlign: "baseline",
    color: mode4(`${c}.500`, `${c}.200`)(props),
    _hover: {
      textDecoration: "underline",
      _disabled: {
        textDecoration: "none"
      }
    },
    _active: {
      color: mode4(`${c}.700`, `${c}.500`)(props)
    }
  };
};
var variantUnstyled = {
  bg: "none",
  color: "inherit",
  display: "inline",
  lineHeight: "inherit",
  m: 0,
  p: 0
};
var variants3 = {
  ghost: variantGhost,
  outline: variantOutline2,
  solid: variantSolid3,
  link: variantLink,
  unstyled: variantUnstyled
};
var sizes3 = {
  lg: {
    h: 12,
    minW: 12,
    fontSize: "lg",
    px: 6
  },
  md: {
    h: 10,
    minW: 10,
    fontSize: "md",
    px: 4
  },
  sm: {
    h: 8,
    minW: 8,
    fontSize: "sm",
    px: 3
  },
  xs: {
    h: 6,
    minW: 6,
    fontSize: "xs",
    px: 2
  }
};
var defaultProps4 = {
  variant: "solid",
  size: "md",
  colorScheme: "gray"
};
var button_default = {
  baseStyle: baseStyle6,
  variants: variants3,
  sizes: sizes3,
  defaultProps: defaultProps4
};

// src/components/checkbox.ts
import { checkboxAnatomy as parts5 } from "@chakra-ui/anatomy";
import { mode as mode5 } from "@chakra-ui/theme-tools";
var baseStyleControl = (props) => {
  const { colorScheme: c } = props;
  return {
    w: "100%",
    transitionProperty: "box-shadow",
    transitionDuration: "normal",
    border: "2px solid",
    borderRadius: "sm",
    borderColor: "inherit",
    color: "white",
    _checked: {
      bg: mode5(`${c}.500`, `${c}.200`)(props),
      borderColor: mode5(`${c}.500`, `${c}.200`)(props),
      color: mode5("white", "gray.900")(props),
      _hover: {
        bg: mode5(`${c}.600`, `${c}.300`)(props),
        borderColor: mode5(`${c}.600`, `${c}.300`)(props)
      },
      _disabled: {
        borderColor: mode5("gray.200", "transparent")(props),
        bg: mode5("gray.200", "whiteAlpha.300")(props),
        color: mode5("gray.500", "whiteAlpha.500")(props)
      }
    },
    _indeterminate: {
      bg: mode5(`${c}.500`, `${c}.200`)(props),
      borderColor: mode5(`${c}.500`, `${c}.200`)(props),
      color: mode5("white", "gray.900")(props)
    },
    _disabled: {
      bg: mode5("gray.100", "whiteAlpha.100")(props),
      borderColor: mode5("gray.100", "transparent")(props)
    },
    _focusVisible: {
      boxShadow: "outline"
    },
    _invalid: {
      borderColor: mode5("red.500", "red.300")(props)
    }
  };
};
var baseStyleContainer3 = {
  _disabled: { cursor: "not-allowed" }
};
var baseStyleLabel = {
  userSelect: "none",
  _disabled: { opacity: 0.4 }
};
var baseStyleIcon2 = {
  transitionProperty: "transform",
  transitionDuration: "normal"
};
var baseStyle7 = (props) => ({
  icon: baseStyleIcon2,
  container: baseStyleContainer3,
  control: baseStyleControl(props),
  label: baseStyleLabel
});
var sizes4 = {
  sm: {
    control: { h: 3, w: 3 },
    label: { fontSize: "sm" },
    icon: { fontSize: "0.45rem" }
  },
  md: {
    control: { w: 4, h: 4 },
    label: { fontSize: "md" },
    icon: { fontSize: "0.625rem" }
  },
  lg: {
    control: { w: 5, h: 5 },
    label: { fontSize: "lg" },
    icon: { fontSize: "0.625rem" }
  }
};
var defaultProps5 = {
  size: "md",
  colorScheme: "blue"
};
var checkbox_default = {
  parts: parts5.keys,
  baseStyle: baseStyle7,
  sizes: sizes4,
  defaultProps: defaultProps5
};

// src/components/close-button.ts
import { cssVar, mode as mode6 } from "@chakra-ui/theme-tools";
var $size = cssVar("close-button-size");
var baseStyle8 = (props) => {
  const hoverBg = mode6(`blackAlpha.100`, `whiteAlpha.100`)(props);
  const activeBg = mode6(`blackAlpha.200`, `whiteAlpha.200`)(props);
  return {
    w: [$size.reference],
    h: [$size.reference],
    borderRadius: "md",
    transitionProperty: "common",
    transitionDuration: "normal",
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
      boxShadow: "none"
    },
    _hover: { bg: hoverBg },
    _active: { bg: activeBg },
    _focusVisible: {
      boxShadow: "outline"
    }
  };
};
var sizes5 = {
  lg: {
    [$size.variable]: "40px",
    fontSize: "16px"
  },
  md: {
    [$size.variable]: "32px",
    fontSize: "12px"
  },
  sm: {
    [$size.variable]: "24px",
    fontSize: "10px"
  }
};
var defaultProps6 = {
  size: "md"
};
var close_button_default = {
  baseStyle: baseStyle8,
  sizes: sizes5,
  defaultProps: defaultProps6
};

// src/components/code.ts
var { variants: variants4, defaultProps: defaultProps7 } = badge_default;
var baseStyle9 = {
  fontFamily: "mono",
  fontSize: "sm",
  px: "0.2em",
  borderRadius: "sm"
};
var code_default = {
  baseStyle: baseStyle9,
  variants: variants4,
  defaultProps: defaultProps7
};

// src/components/container.ts
var baseStyle10 = {
  w: "100%",
  mx: "auto",
  maxW: "60ch",
  px: "1rem"
};
var container_default = {
  baseStyle: baseStyle10
};

// src/components/divider.ts
var baseStyle11 = {
  opacity: 0.6,
  borderColor: "inherit"
};
var variantSolid4 = {
  borderStyle: "solid"
};
var variantDashed = {
  borderStyle: "dashed"
};
var variants5 = {
  solid: variantSolid4,
  dashed: variantDashed
};
var defaultProps8 = {
  variant: "solid"
};
var divider_default = {
  baseStyle: baseStyle11,
  variants: variants5,
  defaultProps: defaultProps8
};

// src/components/drawer.ts
import { drawerAnatomy as parts6 } from "@chakra-ui/anatomy";
import { mode as mode7 } from "@chakra-ui/theme-tools";
function getSize2(value) {
  if (value === "full") {
    return {
      dialog: { maxW: "100vw", h: "100vh" }
    };
  }
  return {
    dialog: { maxW: value }
  };
}
var baseStyleOverlay = {
  bg: "blackAlpha.600",
  zIndex: "overlay"
};
var baseStyleDialogContainer = {
  display: "flex",
  zIndex: "modal",
  justifyContent: "center"
};
var baseStyleDialog = (props) => {
  const { isFullHeight } = props;
  return {
    ...isFullHeight && { height: "100vh" },
    zIndex: "modal",
    maxH: "100vh",
    bg: mode7("white", "gray.700")(props),
    color: "inherit",
    boxShadow: mode7("lg", "dark-lg")(props)
  };
};
var baseStyleHeader = {
  px: 6,
  py: 4,
  fontSize: "xl",
  fontWeight: "semibold"
};
var baseStyleCloseButton = {
  position: "absolute",
  top: 2,
  insetEnd: 3
};
var baseStyleBody = {
  px: 6,
  py: 2,
  flex: 1,
  overflow: "auto"
};
var baseStyleFooter = {
  px: 6,
  py: 4
};
var baseStyle12 = (props) => ({
  overlay: baseStyleOverlay,
  dialogContainer: baseStyleDialogContainer,
  dialog: baseStyleDialog(props),
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  body: baseStyleBody,
  footer: baseStyleFooter
});
var sizes6 = {
  xs: getSize2("xs"),
  sm: getSize2("md"),
  md: getSize2("lg"),
  lg: getSize2("2xl"),
  xl: getSize2("4xl"),
  full: getSize2("full")
};
var defaultProps9 = {
  size: "xs"
};
var drawer_default = {
  parts: parts6.keys,
  baseStyle: baseStyle12,
  sizes: sizes6,
  defaultProps: defaultProps9
};

// src/components/editable.ts
import { editableAnatomy as parts7 } from "@chakra-ui/anatomy";
var baseStylePreview = {
  borderRadius: "md",
  py: "3px",
  transitionProperty: "common",
  transitionDuration: "normal"
};
var baseStyleInput = {
  borderRadius: "md",
  py: "3px",
  transitionProperty: "common",
  transitionDuration: "normal",
  width: "full",
  _focusVisible: { boxShadow: "outline" },
  _placeholder: { opacity: 0.6 }
};
var baseStyleTextarea = {
  borderRadius: "md",
  py: "3px",
  transitionProperty: "common",
  transitionDuration: "normal",
  width: "full",
  _focusVisible: { boxShadow: "outline" },
  _placeholder: { opacity: 0.6 }
};
var baseStyle13 = {
  preview: baseStylePreview,
  input: baseStyleInput,
  textarea: baseStyleTextarea
};
var editable_default = {
  parts: parts7.keys,
  baseStyle: baseStyle13
};

// src/components/form.ts
import { formAnatomy as parts8 } from "@chakra-ui/anatomy";
import { mode as mode8 } from "@chakra-ui/theme-tools";
var baseStyleRequiredIndicator = (props) => {
  return {
    marginStart: 1,
    color: mode8("red.500", "red.300")(props)
  };
};
var baseStyleHelperText = (props) => {
  return {
    mt: 2,
    color: mode8("gray.600", "whiteAlpha.600")(props),
    lineHeight: "normal",
    fontSize: "sm"
  };
};
var baseStyle14 = (props) => ({
  container: { width: "100%", position: "relative" },
  requiredIndicator: baseStyleRequiredIndicator(props),
  helperText: baseStyleHelperText(props)
});
var form_default = {
  parts: parts8.keys,
  baseStyle: baseStyle14
};

// src/components/form-error.ts
import { formErrorAnatomy as parts9 } from "@chakra-ui/anatomy";
import { mode as mode9 } from "@chakra-ui/theme-tools";
var baseStyleText = (props) => {
  return {
    color: mode9("red.500", "red.300")(props),
    mt: 2,
    fontSize: "sm",
    lineHeight: "normal"
  };
};
var baseStyleIcon3 = (props) => {
  return {
    marginEnd: "0.5em",
    color: mode9("red.500", "red.300")(props)
  };
};
var baseStyle15 = (props) => ({
  text: baseStyleText(props),
  icon: baseStyleIcon3(props)
});
var form_error_default = {
  parts: parts9.keys,
  baseStyle: baseStyle15
};

// src/components/form-label.ts
var baseStyle16 = {
  fontSize: "md",
  marginEnd: 3,
  mb: 2,
  fontWeight: "medium",
  transitionProperty: "common",
  transitionDuration: "normal",
  opacity: 1,
  _disabled: {
    opacity: 0.4
  }
};
var form_label_default = {
  baseStyle: baseStyle16
};

// src/components/heading.ts
var baseStyle17 = {
  fontFamily: "heading",
  fontWeight: "bold"
};
var sizes7 = {
  "4xl": {
    fontSize: ["6xl", null, "7xl"],
    lineHeight: 1
  },
  "3xl": {
    fontSize: ["5xl", null, "6xl"],
    lineHeight: 1
  },
  "2xl": {
    fontSize: ["4xl", null, "5xl"],
    lineHeight: [1.2, null, 1]
  },
  xl: {
    fontSize: ["3xl", null, "4xl"],
    lineHeight: [1.33, null, 1.2]
  },
  lg: {
    fontSize: ["2xl", null, "3xl"],
    lineHeight: [1.33, null, 1.2]
  },
  md: { fontSize: "xl", lineHeight: 1.2 },
  sm: { fontSize: "md", lineHeight: 1.2 },
  xs: { fontSize: "sm", lineHeight: 1.2 }
};
var defaultProps10 = {
  size: "xl"
};
var heading_default = {
  baseStyle: baseStyle17,
  sizes: sizes7,
  defaultProps: defaultProps10
};

// src/components/input.ts
import { inputAnatomy as parts10 } from "@chakra-ui/anatomy";
import { getColor as getColor3, mode as mode10 } from "@chakra-ui/theme-tools";
var baseStyle18 = {
  field: {
    width: "100%",
    minWidth: 0,
    outline: 0,
    position: "relative",
    appearance: "none",
    transitionProperty: "common",
    transitionDuration: "normal"
  }
};
var size = {
  lg: {
    fontSize: "lg",
    px: 4,
    h: 12,
    borderRadius: "md"
  },
  md: {
    fontSize: "md",
    px: 4,
    h: 10,
    borderRadius: "md"
  },
  sm: {
    fontSize: "sm",
    px: 3,
    h: 8,
    borderRadius: "sm"
  },
  xs: {
    fontSize: "xs",
    px: 2,
    h: 6,
    borderRadius: "sm"
  }
};
var sizes8 = {
  lg: {
    field: size.lg,
    addon: size.lg
  },
  md: {
    field: size.md,
    addon: size.md
  },
  sm: {
    field: size.sm,
    addon: size.sm
  },
  xs: {
    field: size.xs,
    addon: size.xs
  }
};
function getDefaults(props) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || mode10("blue.500", "blue.300")(props),
    errorBorderColor: ec || mode10("red.500", "red.300")(props)
  };
}
var variantOutline3 = (props) => {
  const { theme: theme2 } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);
  return {
    field: {
      border: "1px solid",
      borderColor: "inherit",
      bg: "inherit",
      _hover: {
        borderColor: mode10("gray.300", "whiteAlpha.400")(props)
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all"
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed"
      },
      _invalid: {
        borderColor: getColor3(theme2, ec),
        boxShadow: `0 0 0 1px ${getColor3(theme2, ec)}`
      },
      _focusVisible: {
        zIndex: 1,
        borderColor: getColor3(theme2, fc),
        boxShadow: `0 0 0 1px ${getColor3(theme2, fc)}`
      }
    },
    addon: {
      border: "1px solid",
      borderColor: mode10("inherit", "whiteAlpha.50")(props),
      bg: mode10("gray.100", "whiteAlpha.300")(props)
    }
  };
};
var variantFilled = (props) => {
  const { theme: theme2 } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);
  return {
    field: {
      border: "2px solid",
      borderColor: "transparent",
      bg: mode10("gray.100", "whiteAlpha.50")(props),
      _hover: {
        bg: mode10("gray.200", "whiteAlpha.100")(props)
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all"
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed"
      },
      _invalid: {
        borderColor: getColor3(theme2, ec)
      },
      _focusVisible: {
        bg: "transparent",
        borderColor: getColor3(theme2, fc)
      }
    },
    addon: {
      border: "2px solid",
      borderColor: "transparent",
      bg: mode10("gray.100", "whiteAlpha.50")(props)
    }
  };
};
var variantFlushed = (props) => {
  const { theme: theme2 } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);
  return {
    field: {
      borderBottom: "1px solid",
      borderColor: "inherit",
      borderRadius: 0,
      px: 0,
      bg: "transparent",
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all"
      },
      _invalid: {
        borderColor: getColor3(theme2, ec),
        boxShadow: `0px 1px 0px 0px ${getColor3(theme2, ec)}`
      },
      _focusVisible: {
        borderColor: getColor3(theme2, fc),
        boxShadow: `0px 1px 0px 0px ${getColor3(theme2, fc)}`
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed"
      }
    },
    addon: {
      borderBottom: "2px solid",
      borderColor: "inherit",
      borderRadius: 0,
      px: 0,
      bg: "transparent"
    }
  };
};
var variantUnstyled2 = {
  field: {
    bg: "transparent",
    px: 0,
    height: "auto",
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed"
    }
  },
  addon: {
    bg: "transparent",
    px: 0,
    height: "auto"
  }
};
var variants6 = {
  outline: variantOutline3,
  filled: variantFilled,
  flushed: variantFlushed,
  unstyled: variantUnstyled2
};
var defaultProps11 = {
  size: "md",
  variant: "outline"
};
var input_default = {
  parts: parts10.keys,
  baseStyle: baseStyle18,
  sizes: sizes8,
  variants: variants6,
  defaultProps: defaultProps11
};

// src/components/kbd.ts
import { mode as mode11 } from "@chakra-ui/theme-tools";
var baseStyle19 = (props) => {
  return {
    bg: mode11("gray.100", "whiteAlpha")(props),
    borderRadius: "md",
    borderWidth: "1px",
    borderBottomWidth: "3px",
    fontSize: "0.8em",
    fontWeight: "bold",
    lineHeight: "normal",
    px: "0.4em",
    whiteSpace: "nowrap"
  };
};
var kbd_default = {
  baseStyle: baseStyle19
};

// src/components/link.ts
var baseStyle20 = {
  transitionProperty: "common",
  transitionDuration: "fast",
  transitionTimingFunction: "ease-out",
  cursor: "pointer",
  textDecoration: "none",
  outline: "none",
  color: "inherit",
  _hover: {
    textDecoration: "underline"
  },
  _focusVisible: {
    boxShadow: "outline"
  }
};
var link_default = {
  baseStyle: baseStyle20
};

// src/components/list.ts
import { listAnatomy as parts11 } from "@chakra-ui/anatomy";
var baseStyleIcon4 = {
  marginEnd: "0.5rem",
  display: "inline",
  verticalAlign: "text-bottom"
};
var baseStyle21 = {
  container: {},
  item: {},
  icon: baseStyleIcon4
};
var list_default = {
  parts: parts11.keys,
  baseStyle: baseStyle21
};

// src/components/menu.ts
import { menuAnatomy as parts12 } from "@chakra-ui/anatomy";
import { mode as mode12 } from "@chakra-ui/theme-tools";
var baseStyleList = (props) => {
  return {
    bg: mode12("#fff", "gray.700")(props),
    boxShadow: mode12("sm", "dark-lg")(props),
    color: "inherit",
    minW: "3xs",
    py: "2",
    zIndex: 1,
    borderRadius: "md",
    borderWidth: "1px"
  };
};
var baseStyleItem = (props) => {
  return {
    py: "0.4rem",
    px: "0.8rem",
    transitionProperty: "background",
    transitionDuration: "ultra-fast",
    transitionTimingFunction: "ease-in",
    _focus: {
      bg: mode12("gray.100", "whiteAlpha.100")(props)
    },
    _active: {
      bg: mode12("gray.200", "whiteAlpha.200")(props)
    },
    _expanded: {
      bg: mode12("gray.100", "whiteAlpha.100")(props)
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed"
    }
  };
};
var baseStyleGroupTitle = {
  mx: 4,
  my: 2,
  fontWeight: "semibold",
  fontSize: "sm"
};
var baseStyleCommand = {
  opacity: 0.6
};
var baseStyleDivider = {
  border: 0,
  borderBottom: "1px solid",
  borderColor: "inherit",
  my: "0.5rem",
  opacity: 0.6
};
var baseStyleButton2 = {
  transitionProperty: "common",
  transitionDuration: "normal"
};
var baseStyle22 = (props) => ({
  button: baseStyleButton2,
  list: baseStyleList(props),
  item: baseStyleItem(props),
  groupTitle: baseStyleGroupTitle,
  command: baseStyleCommand,
  divider: baseStyleDivider
});
var menu_default = {
  parts: parts12.keys,
  baseStyle: baseStyle22
};

// src/components/modal.ts
import { modalAnatomy as parts13 } from "@chakra-ui/anatomy";
import { mode as mode13 } from "@chakra-ui/theme-tools";
var baseStyleOverlay2 = {
  bg: "blackAlpha.600",
  zIndex: "modal"
};
var baseStyleDialogContainer2 = (props) => {
  const { isCentered, scrollBehavior } = props;
  return {
    display: "flex",
    zIndex: "modal",
    justifyContent: "center",
    alignItems: isCentered ? "center" : "flex-start",
    overflow: scrollBehavior === "inside" ? "hidden" : "auto"
  };
};
var baseStyleDialog2 = (props) => {
  const { scrollBehavior } = props;
  return {
    borderRadius: "md",
    bg: mode13("white", "gray.700")(props),
    color: "inherit",
    my: "3.75rem",
    zIndex: "modal",
    maxH: scrollBehavior === "inside" ? "calc(100% - 7.5rem)" : void 0,
    boxShadow: mode13("lg", "dark-lg")(props)
  };
};
var baseStyleHeader2 = {
  px: 6,
  py: 4,
  fontSize: "xl",
  fontWeight: "semibold"
};
var baseStyleCloseButton2 = {
  position: "absolute",
  top: 2,
  insetEnd: 3
};
var baseStyleBody2 = (props) => {
  const { scrollBehavior } = props;
  return {
    px: 6,
    py: 2,
    flex: 1,
    overflow: scrollBehavior === "inside" ? "auto" : void 0
  };
};
var baseStyleFooter2 = {
  px: 6,
  py: 4
};
var baseStyle23 = (props) => ({
  overlay: baseStyleOverlay2,
  dialogContainer: baseStyleDialogContainer2(props),
  dialog: baseStyleDialog2(props),
  header: baseStyleHeader2,
  closeButton: baseStyleCloseButton2,
  body: baseStyleBody2(props),
  footer: baseStyleFooter2
});
function getSize3(value) {
  if (value === "full") {
    return {
      dialog: {
        maxW: "100vw",
        minH: "100vh",
        "@supports(min-height: -webkit-fill-available)": {
          minH: "-webkit-fill-available"
        },
        my: 0,
        borderRadius: 0
      }
    };
  }
  return {
    dialog: { maxW: value }
  };
}
var sizes9 = {
  xs: getSize3("xs"),
  sm: getSize3("sm"),
  md: getSize3("md"),
  lg: getSize3("lg"),
  xl: getSize3("xl"),
  "2xl": getSize3("2xl"),
  "3xl": getSize3("3xl"),
  "4xl": getSize3("4xl"),
  "5xl": getSize3("5xl"),
  "6xl": getSize3("6xl"),
  full: getSize3("full")
};
var defaultProps12 = {
  size: "md"
};
var modal_default = {
  parts: parts13.keys,
  baseStyle: baseStyle23,
  sizes: sizes9,
  defaultProps: defaultProps12
};

// src/components/number-input.ts
import { numberInputAnatomy as parts14 } from "@chakra-ui/anatomy";
import { calc, cssVar as cssVar2, mode as mode14 } from "@chakra-ui/theme-tools";

// src/foundations/typography.ts
var typography = {
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em"
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem"
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
  fonts: {
    heading: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem"
  }
};
var typography_default = typography;

// src/components/number-input.ts
var { variants: variants7, defaultProps: defaultProps13 } = input_default;
var $stepperWidth = cssVar2("number-input-stepper-width");
var $inputPadding = cssVar2("number-input-input-padding");
var inputPaddingValue = calc($stepperWidth).add("0.5rem").toString();
var baseStyleRoot = {
  [$stepperWidth.variable]: "24px",
  [$inputPadding.variable]: inputPaddingValue
};
var _a;
var baseStyleField = ((_a = input_default.baseStyle) == null ? void 0 : _a.field) ?? {};
var baseStyleStepperGroup = {
  width: [$stepperWidth.reference]
};
var baseStyleStepper = (props) => {
  return {
    borderStart: "1px solid",
    borderStartColor: mode14("inherit", "whiteAlpha.300")(props),
    color: mode14("inherit", "whiteAlpha.800")(props),
    _active: {
      bg: mode14("gray.200", "whiteAlpha.300")(props)
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed"
    }
  };
};
var baseStyle24 = (props) => ({
  root: baseStyleRoot,
  field: baseStyleField,
  stepperGroup: baseStyleStepperGroup,
  stepper: baseStyleStepper(props)
});
function getSize4(size2) {
  var _a2;
  const sizeStyle = input_default.sizes[size2];
  const radius = {
    lg: "md",
    md: "md",
    sm: "sm",
    xs: "sm"
  };
  const _fontSize = ((_a2 = sizeStyle.field) == null ? void 0 : _a2.fontSize) ?? "md";
  const fontSize = typography_default.fontSizes[_fontSize];
  return {
    field: {
      ...sizeStyle.field,
      paddingInlineEnd: $inputPadding.reference,
      verticalAlign: "top"
    },
    stepper: {
      fontSize: calc(fontSize).multiply(0.75).toString(),
      _first: {
        borderTopEndRadius: radius[size2]
      },
      _last: {
        borderBottomEndRadius: radius[size2],
        mt: "-1px",
        borderTopWidth: 1
      }
    }
  };
}
var sizes10 = {
  xs: getSize4("xs"),
  sm: getSize4("sm"),
  md: getSize4("md"),
  lg: getSize4("lg")
};
var number_input_default = {
  parts: parts14.keys,
  baseStyle: baseStyle24,
  sizes: sizes10,
  variants: variants7,
  defaultProps: defaultProps13
};

// src/components/pin-input.ts
var baseStyle25 = {
  ...input_default.baseStyle.field,
  textAlign: "center"
};
var sizes11 = {
  lg: {
    fontSize: "lg",
    w: 12,
    h: 12,
    borderRadius: "md"
  },
  md: {
    fontSize: "md",
    w: 10,
    h: 10,
    borderRadius: "md"
  },
  sm: {
    fontSize: "sm",
    w: 8,
    h: 8,
    borderRadius: "sm"
  },
  xs: {
    fontSize: "xs",
    w: 6,
    h: 6,
    borderRadius: "sm"
  }
};
var variants8 = {
  outline: (props) => input_default.variants.outline(props).field ?? {},
  flushed: (props) => input_default.variants.flushed(props).field ?? {},
  filled: (props) => input_default.variants.filled(props).field ?? {},
  unstyled: input_default.variants.unstyled.field ?? {}
};
var defaultProps14 = input_default.defaultProps;
var pin_input_default = {
  baseStyle: baseStyle25,
  sizes: sizes11,
  variants: variants8,
  defaultProps: defaultProps14
};

// src/components/popover.ts
import { popoverAnatomy as parts15 } from "@chakra-ui/anatomy";
import { cssVar as cssVar3, mode as mode15 } from "@chakra-ui/theme-tools";
var $popperBg = cssVar3("popper-bg");
var $arrowBg = cssVar3("popper-arrow-bg");
var $arrowShadowColor = cssVar3("popper-arrow-shadow-color");
var baseStylePopper = {
  zIndex: 10
};
var baseStyleContent = (props) => {
  const bg = mode15("white", "gray.700")(props);
  const shadowColor = mode15("gray.200", "whiteAlpha.300")(props);
  return {
    [$popperBg.variable]: `colors.${bg}`,
    bg: $popperBg.reference,
    [$arrowBg.variable]: $popperBg.reference,
    [$arrowShadowColor.variable]: `colors.${shadowColor}`,
    width: "xs",
    border: "1px solid",
    borderColor: "inherit",
    borderRadius: "md",
    boxShadow: "sm",
    zIndex: "inherit",
    _focusVisible: {
      outline: 0,
      boxShadow: "outline"
    }
  };
};
var baseStyleHeader3 = {
  px: 3,
  py: 2,
  borderBottomWidth: "1px"
};
var baseStyleBody3 = {
  px: 3,
  py: 2
};
var baseStyleFooter3 = {
  px: 3,
  py: 2,
  borderTopWidth: "1px"
};
var baseStyleCloseButton3 = {
  position: "absolute",
  borderRadius: "md",
  top: 1,
  insetEnd: 2,
  padding: 2
};
var baseStyle26 = (props) => ({
  popper: baseStylePopper,
  content: baseStyleContent(props),
  header: baseStyleHeader3,
  body: baseStyleBody3,
  footer: baseStyleFooter3,
  arrow: {},
  closeButton: baseStyleCloseButton3
});
var popover_default = {
  parts: parts15.keys,
  baseStyle: baseStyle26
};

// src/components/progress.ts
import { progressAnatomy as parts16 } from "@chakra-ui/anatomy";
import { generateStripe, getColor as getColor4, mode as mode16 } from "@chakra-ui/theme-tools";
function filledStyle(props) {
  const { colorScheme: c, theme: t, isIndeterminate, hasStripe } = props;
  const stripeStyle = mode16(generateStripe(), generateStripe("1rem", "rgba(0,0,0,0.1)"))(props);
  const bgColor = mode16(`${c}.500`, `${c}.200`)(props);
  const gradient = `linear-gradient(
    to right,
    transparent 0%,
    ${getColor4(t, bgColor)} 50%,
    transparent 100%
  )`;
  const addStripe = !isIndeterminate && hasStripe;
  return {
    ...addStripe && stripeStyle,
    ...isIndeterminate ? { bgImage: gradient } : { bgColor }
  };
}
var baseStyleLabel2 = {
  lineHeight: "1",
  fontSize: "0.25em",
  fontWeight: "bold",
  color: "white"
};
var baseStyleTrack = (props) => {
  return {
    bg: mode16("gray.100", "whiteAlpha.300")(props)
  };
};
var baseStyleFilledTrack = (props) => {
  return {
    transitionProperty: "common",
    transitionDuration: "slow",
    ...filledStyle(props)
  };
};
var baseStyle27 = (props) => ({
  label: baseStyleLabel2,
  filledTrack: baseStyleFilledTrack(props),
  track: baseStyleTrack(props)
});
var sizes12 = {
  xs: {
    track: { h: "0.25rem" }
  },
  sm: {
    track: { h: "0.5rem" }
  },
  md: {
    track: { h: "0.75rem" }
  },
  lg: {
    track: { h: "1rem" }
  }
};
var defaultProps15 = {
  size: "md",
  colorScheme: "blue"
};
var progress_default = {
  parts: parts16.keys,
  sizes: sizes12,
  baseStyle: baseStyle27,
  defaultProps: defaultProps15
};

// src/components/radio.ts
import { radioAnatomy as parts17 } from "@chakra-ui/anatomy";
var baseStyleControl2 = (props) => {
  const { control = {} } = checkbox_default.baseStyle(props);
  return {
    ...control,
    borderRadius: "full",
    _checked: {
      ...control["_checked"],
      _before: {
        content: `""`,
        display: "inline-block",
        pos: "relative",
        w: "50%",
        h: "50%",
        borderRadius: "50%",
        bg: "currentColor"
      }
    }
  };
};
var baseStyle28 = (props) => ({
  label: checkbox_default.baseStyle(props).label,
  container: checkbox_default.baseStyle(props).container,
  control: baseStyleControl2(props)
});
var sizes13 = {
  md: {
    control: { w: 4, h: 4 },
    label: { fontSize: "md" }
  },
  lg: {
    control: { w: 5, h: 5 },
    label: { fontSize: "lg" }
  },
  sm: {
    control: { width: 3, height: 3 },
    label: { fontSize: "sm" }
  }
};
var defaultProps16 = {
  size: "md",
  colorScheme: "blue"
};
var radio_default = {
  parts: parts17.keys,
  baseStyle: baseStyle28,
  sizes: sizes13,
  defaultProps: defaultProps16
};

// src/components/select.ts
import { selectAnatomy as parts18 } from "@chakra-ui/anatomy";
import { mergeWith } from "@chakra-ui/utils";
import { mode as mode17 } from "@chakra-ui/theme-tools";
var baseStyleField2 = (props) => {
  return {
    ...input_default.baseStyle.field,
    bg: mode17("white", "gray.700")(props),
    appearance: "none",
    paddingBottom: "1px",
    lineHeight: "normal",
    "> option, > optgroup": {
      bg: mode17("white", "gray.700")(props)
    }
  };
};
var baseStyleIcon5 = {
  width: "1.5rem",
  height: "100%",
  insetEnd: "0.5rem",
  position: "relative",
  color: "currentColor",
  fontSize: "1.25rem",
  _disabled: {
    opacity: 0.5
  }
};
var baseStyle29 = (props) => ({
  field: baseStyleField2(props),
  icon: baseStyleIcon5
});
var iconSpacing = { paddingInlineEnd: "2rem" };
var sizes14 = mergeWith({}, input_default.sizes, {
  lg: {
    field: iconSpacing
  },
  md: {
    field: iconSpacing
  },
  sm: {
    field: iconSpacing
  },
  xs: {
    field: iconSpacing,
    icon: { insetEnd: "0.25rem" }
  }
});
var select_default = {
  parts: parts18.keys,
  baseStyle: baseStyle29,
  sizes: sizes14,
  variants: input_default.variants,
  defaultProps: input_default.defaultProps
};

// src/components/skeleton.ts
import { getColor as getColor5, mode as mode18, cssVar as cssVar4 } from "@chakra-ui/theme-tools";
var $startColor = cssVar4("skeleton-start-color");
var $endColor = cssVar4("skeleton-end-color");
var baseStyle30 = (props) => {
  const defaultStartColor = mode18("gray.100", "gray.800")(props);
  const defaultEndColor = mode18("gray.400", "gray.600")(props);
  const {
    startColor = defaultStartColor,
    endColor = defaultEndColor,
    theme: theme2
  } = props;
  const start = getColor5(theme2, startColor);
  const end = getColor5(theme2, endColor);
  return {
    [$startColor.variable]: start,
    [$endColor.variable]: end,
    opacity: 0.7,
    borderRadius: "2px",
    borderColor: start,
    background: end
  };
};
var skeleton_default = {
  baseStyle: baseStyle30
};

// src/components/skip-link.ts
import { mode as mode19 } from "@chakra-ui/theme-tools";
var baseStyle31 = (props) => ({
  borderRadius: "md",
  fontWeight: "semibold",
  _focusVisible: {
    boxShadow: "outline",
    padding: "1rem",
    position: "fixed",
    top: "1.5rem",
    insetStart: "1.5rem",
    bg: mode19("white", "gray.700")(props)
  }
});
var skip_link_default = {
  baseStyle: baseStyle31
};

// src/components/slider.ts
import { sliderAnatomy as parts19 } from "@chakra-ui/anatomy";
import { mode as mode20, orient } from "@chakra-ui/theme-tools";
function thumbOrientation(props) {
  return orient({
    orientation: props.orientation,
    vertical: {
      left: "50%",
      transform: `translateX(-50%)`,
      _active: {
        transform: `translateX(-50%) scale(1.15)`
      }
    },
    horizontal: {
      top: "50%",
      transform: `translateY(-50%)`,
      _active: {
        transform: `translateY(-50%) scale(1.15)`
      }
    }
  });
}
var baseStyleContainer4 = (props) => {
  const { orientation } = props;
  return {
    display: "inline-block",
    position: "relative",
    cursor: "pointer",
    _disabled: {
      opacity: 0.6,
      cursor: "default",
      pointerEvents: "none"
    },
    ...orient({
      orientation,
      vertical: { h: "100%" },
      horizontal: { w: "100%" }
    })
  };
};
var baseStyleTrack2 = (props) => {
  return {
    overflow: "hidden",
    borderRadius: "sm",
    bg: mode20("gray.200", "whiteAlpha.200")(props),
    _disabled: {
      bg: mode20("gray.300", "whiteAlpha.300")(props)
    }
  };
};
var baseStyleThumb = (props) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    outline: 0,
    zIndex: 1,
    borderRadius: "full",
    bg: "white",
    boxShadow: "base",
    border: "1px solid",
    borderColor: "transparent",
    transitionProperty: "transform",
    transitionDuration: "normal",
    _focusVisible: { boxShadow: "outline" },
    _disabled: { bg: "gray.300" },
    ...thumbOrientation(props)
  };
};
var baseStyleFilledTrack2 = (props) => {
  const { colorScheme: c } = props;
  return {
    width: "inherit",
    height: "inherit",
    bg: mode20(`${c}.500`, `${c}.200`)(props)
  };
};
var baseStyle32 = (props) => ({
  container: baseStyleContainer4(props),
  track: baseStyleTrack2(props),
  thumb: baseStyleThumb(props),
  filledTrack: baseStyleFilledTrack2(props)
});
var sizeLg = (props) => {
  return {
    thumb: { w: "16px", h: "16px" },
    track: orient({
      orientation: props.orientation,
      horizontal: { h: "4px" },
      vertical: { w: "4px" }
    })
  };
};
var sizeMd = (props) => {
  return {
    thumb: { w: "14px", h: "14px" },
    track: orient({
      orientation: props.orientation,
      horizontal: { h: "4px" },
      vertical: { w: "4px" }
    })
  };
};
var sizeSm = (props) => {
  return {
    thumb: { w: "10px", h: "10px" },
    track: orient({
      orientation: props.orientation,
      horizontal: { h: "2px" },
      vertical: { w: "2px" }
    })
  };
};
var sizes15 = {
  lg: sizeLg,
  md: sizeMd,
  sm: sizeSm
};
var defaultProps17 = {
  size: "md",
  colorScheme: "blue"
};
var slider_default = {
  parts: parts19.keys,
  sizes: sizes15,
  baseStyle: baseStyle32,
  defaultProps: defaultProps17
};

// src/components/spinner.ts
import { cssVar as cssVar5 } from "@chakra-ui/theme-tools";
var $size2 = cssVar5("spinner-size");
var baseStyle33 = {
  width: [$size2.reference],
  height: [$size2.reference]
};
var sizes16 = {
  xs: {
    [$size2.variable]: "0.75rem"
  },
  sm: {
    [$size2.variable]: "1rem"
  },
  md: {
    [$size2.variable]: "1.5rem"
  },
  lg: {
    [$size2.variable]: "2rem"
  },
  xl: {
    [$size2.variable]: "3rem"
  }
};
var defaultProps18 = {
  size: "md"
};
var spinner_default = {
  baseStyle: baseStyle33,
  sizes: sizes16,
  defaultProps: defaultProps18
};

// src/components/stat.ts
import { statAnatomy as parts20 } from "@chakra-ui/anatomy";
var baseStyleLabel3 = {
  fontWeight: "medium"
};
var baseStyleHelpText = {
  opacity: 0.8,
  marginBottom: 2
};
var baseStyleNumber = {
  verticalAlign: "baseline",
  fontWeight: "semibold"
};
var baseStyleIcon6 = {
  marginEnd: 1,
  w: "14px",
  h: "14px",
  verticalAlign: "middle"
};
var baseStyle34 = {
  container: {},
  label: baseStyleLabel3,
  helpText: baseStyleHelpText,
  number: baseStyleNumber,
  icon: baseStyleIcon6
};
var sizes17 = {
  md: {
    label: { fontSize: "sm" },
    helpText: { fontSize: "sm" },
    number: { fontSize: "2xl" }
  }
};
var defaultProps19 = {
  size: "md"
};
var stat_default = {
  parts: parts20.keys,
  baseStyle: baseStyle34,
  sizes: sizes17,
  defaultProps: defaultProps19
};

// src/components/switch.ts
import { switchAnatomy as parts21 } from "@chakra-ui/anatomy";
import { calc as calc2, cssVar as cssVar6, mode as mode21 } from "@chakra-ui/theme-tools";
var $width = cssVar6("switch-track-width");
var $height = cssVar6("switch-track-height");
var $diff = cssVar6("switch-track-diff");
var diffValue = calc2.subtract($width, $height);
var $translateX = cssVar6("switch-thumb-x");
var baseStyleTrack3 = (props) => {
  const { colorScheme: c } = props;
  return {
    borderRadius: "full",
    p: "2px",
    width: [$width.reference],
    height: [$height.reference],
    transitionProperty: "common",
    transitionDuration: "fast",
    bg: mode21("gray.300", "whiteAlpha.400")(props),
    _focusVisible: {
      boxShadow: "outline"
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed"
    },
    _checked: {
      bg: mode21(`${c}.500`, `${c}.200`)(props)
    }
  };
};
var baseStyleThumb2 = {
  bg: "white",
  transitionProperty: "transform",
  transitionDuration: "normal",
  borderRadius: "inherit",
  width: [$height.reference],
  height: [$height.reference],
  _checked: {
    transform: `translateX(${$translateX.reference})`
  }
};
var baseStyle35 = (props) => ({
  container: {
    [$diff.variable]: diffValue,
    [$translateX.variable]: $diff.reference,
    _rtl: {
      [$translateX.variable]: calc2($diff).negate().toString()
    }
  },
  track: baseStyleTrack3(props),
  thumb: baseStyleThumb2
});
var sizes18 = {
  sm: {
    container: {
      [$width.variable]: "1.375rem",
      [$height.variable]: "0.75rem"
    }
  },
  md: {
    container: {
      [$width.variable]: "1.875rem",
      [$height.variable]: "1rem"
    }
  },
  lg: {
    container: {
      [$width.variable]: "2.875rem",
      [$height.variable]: "1.5rem"
    }
  }
};
var defaultProps20 = {
  size: "md",
  colorScheme: "blue"
};
var switch_default = {
  parts: parts21.keys,
  baseStyle: baseStyle35,
  sizes: sizes18,
  defaultProps: defaultProps20
};

// src/components/table.ts
import { tableAnatomy as parts22 } from "@chakra-ui/anatomy";
import { mode as mode22 } from "@chakra-ui/theme-tools";
var baseStyle36 = {
  table: {
    fontVariantNumeric: "lining-nums tabular-nums",
    borderCollapse: "collapse",
    width: "full"
  },
  th: {
    fontFamily: "heading",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "wider",
    textAlign: "start"
  },
  td: {
    textAlign: "start"
  },
  caption: {
    mt: 4,
    fontFamily: "heading",
    textAlign: "center",
    fontWeight: "medium"
  }
};
var numericStyles = {
  "&[data-is-numeric=true]": {
    textAlign: "end"
  }
};
var variantSimple = (props) => {
  const { colorScheme: c } = props;
  return {
    th: {
      color: mode22("gray.600", "gray.400")(props),
      borderBottom: "1px",
      borderColor: mode22(`${c}.100`, `${c}.700`)(props),
      ...numericStyles
    },
    td: {
      borderBottom: "1px",
      borderColor: mode22(`${c}.100`, `${c}.700`)(props),
      ...numericStyles
    },
    caption: {
      color: mode22("gray.600", "gray.100")(props)
    },
    tfoot: {
      tr: {
        "&:last-of-type": {
          th: { borderBottomWidth: 0 }
        }
      }
    }
  };
};
var variantStripe = (props) => {
  const { colorScheme: c } = props;
  return {
    th: {
      color: mode22("gray.600", "gray.400")(props),
      borderBottom: "1px",
      borderColor: mode22(`${c}.100`, `${c}.700`)(props),
      ...numericStyles
    },
    td: {
      borderBottom: "1px",
      borderColor: mode22(`${c}.100`, `${c}.700`)(props),
      ...numericStyles
    },
    caption: {
      color: mode22("gray.600", "gray.100")(props)
    },
    tbody: {
      tr: {
        "&:nth-of-type(odd)": {
          "th, td": {
            borderBottomWidth: "1px",
            borderColor: mode22(`${c}.100`, `${c}.700`)(props)
          },
          td: {
            background: mode22(`${c}.100`, `${c}.700`)(props)
          }
        }
      }
    },
    tfoot: {
      tr: {
        "&:last-of-type": {
          th: { borderBottomWidth: 0 }
        }
      }
    }
  };
};
var variants9 = {
  simple: variantSimple,
  striped: variantStripe,
  unstyled: {}
};
var sizes19 = {
  sm: {
    th: {
      px: "4",
      py: "1",
      lineHeight: "4",
      fontSize: "xs"
    },
    td: {
      px: "4",
      py: "2",
      fontSize: "sm",
      lineHeight: "4"
    },
    caption: {
      px: "4",
      py: "2",
      fontSize: "xs"
    }
  },
  md: {
    th: {
      px: "6",
      py: "3",
      lineHeight: "4",
      fontSize: "xs"
    },
    td: {
      px: "6",
      py: "4",
      lineHeight: "5"
    },
    caption: {
      px: "6",
      py: "2",
      fontSize: "sm"
    }
  },
  lg: {
    th: {
      px: "8",
      py: "4",
      lineHeight: "5",
      fontSize: "sm"
    },
    td: {
      px: "8",
      py: "5",
      lineHeight: "6"
    },
    caption: {
      px: "6",
      py: "2",
      fontSize: "md"
    }
  }
};
var defaultProps21 = {
  variant: "simple",
  size: "md",
  colorScheme: "gray"
};
var table_default = {
  parts: parts22.keys,
  baseStyle: baseStyle36,
  variants: variants9,
  sizes: sizes19,
  defaultProps: defaultProps21
};

// src/components/tabs.ts
import { tabsAnatomy as parts23 } from "@chakra-ui/anatomy";
import { getColor as getColor6, mode as mode23 } from "@chakra-ui/theme-tools";
var baseStyleRoot2 = (props) => {
  const { orientation } = props;
  return {
    display: orientation === "vertical" ? "flex" : "block"
  };
};
var baseStyleTab = (props) => {
  const { isFitted } = props;
  return {
    flex: isFitted ? 1 : void 0,
    transitionProperty: "common",
    transitionDuration: "normal",
    _focusVisible: {
      zIndex: 1,
      boxShadow: "outline"
    },
    _disabled: {
      cursor: "not-allowed",
      opacity: 0.4
    }
  };
};
var baseStyleTablist = (props) => {
  const { align = "start", orientation } = props;
  const alignments = {
    end: "flex-end",
    center: "center",
    start: "flex-start"
  };
  return {
    justifyContent: alignments[align],
    flexDirection: orientation === "vertical" ? "column" : "row"
  };
};
var baseStyleTabpanel = {
  p: 4
};
var baseStyle37 = (props) => ({
  root: baseStyleRoot2(props),
  tab: baseStyleTab(props),
  tablist: baseStyleTablist(props),
  tabpanel: baseStyleTabpanel
});
var sizes20 = {
  sm: {
    tab: {
      py: 1,
      px: 4,
      fontSize: "sm"
    }
  },
  md: {
    tab: {
      fontSize: "md",
      py: 2,
      px: 4
    }
  },
  lg: {
    tab: {
      fontSize: "lg",
      py: 3,
      px: 4
    }
  }
};
var variantLine = (props) => {
  const { colorScheme: c, orientation } = props;
  const isVertical = orientation === "vertical";
  const borderProp = orientation === "vertical" ? "borderStart" : "borderBottom";
  const marginProp = isVertical ? "marginStart" : "marginBottom";
  return {
    tablist: {
      [borderProp]: "2px solid",
      borderColor: "inherit"
    },
    tab: {
      [borderProp]: "2px solid",
      borderColor: "transparent",
      [marginProp]: "-2px",
      _selected: {
        color: mode23(`${c}.600`, `${c}.300`)(props),
        borderColor: "currentColor"
      },
      _active: {
        bg: mode23("gray.200", "whiteAlpha.300")(props)
      },
      _disabled: {
        _active: { bg: "none" }
      }
    }
  };
};
var variantEnclosed = (props) => {
  const { colorScheme: c } = props;
  return {
    tab: {
      borderTopRadius: "md",
      border: "1px solid",
      borderColor: "transparent",
      mb: "-1px",
      _selected: {
        color: mode23(`${c}.600`, `${c}.300`)(props),
        borderColor: "inherit",
        borderBottomColor: mode23(`white`, `gray.800`)(props)
      }
    },
    tablist: {
      mb: "-1px",
      borderBottom: "1px solid",
      borderColor: "inherit"
    }
  };
};
var variantEnclosedColored = (props) => {
  const { colorScheme: c } = props;
  return {
    tab: {
      border: "1px solid",
      borderColor: "inherit",
      bg: mode23(`gray.50`, `whiteAlpha.50`)(props),
      mb: "-1px",
      _notLast: {
        marginEnd: "-1px"
      },
      _selected: {
        bg: mode23("#fff", "gray.800")(props),
        color: mode23(`${c}.600`, `${c}.300`)(props),
        borderColor: "inherit",
        borderTopColor: "currentColor",
        borderBottomColor: "transparent"
      }
    },
    tablist: {
      mb: "-1px",
      borderBottom: "1px solid",
      borderColor: "inherit"
    }
  };
};
var variantSoftRounded = (props) => {
  const { colorScheme: c, theme: theme2 } = props;
  return {
    tab: {
      borderRadius: "full",
      fontWeight: "semibold",
      color: "gray.600",
      _selected: {
        color: getColor6(theme2, `${c}.700`),
        bg: getColor6(theme2, `${c}.100`)
      }
    }
  };
};
var variantSolidRounded = (props) => {
  const { colorScheme: c } = props;
  return {
    tab: {
      borderRadius: "full",
      fontWeight: "semibold",
      color: mode23("gray.600", "inherit")(props),
      _selected: {
        color: mode23(`#fff`, "gray.800")(props),
        bg: mode23(`${c}.600`, `${c}.300`)(props)
      }
    }
  };
};
var variantUnstyled3 = {};
var variants10 = {
  line: variantLine,
  enclosed: variantEnclosed,
  "enclosed-colored": variantEnclosedColored,
  "soft-rounded": variantSoftRounded,
  "solid-rounded": variantSolidRounded,
  unstyled: variantUnstyled3
};
var defaultProps22 = {
  size: "md",
  variant: "line",
  colorScheme: "blue"
};
var tabs_default = {
  parts: parts23.keys,
  baseStyle: baseStyle37,
  sizes: sizes20,
  variants: variants10,
  defaultProps: defaultProps22
};

// src/components/tag.ts
import { tagAnatomy as parts24 } from "@chakra-ui/anatomy";
var baseStyleContainer5 = {
  fontWeight: "medium",
  lineHeight: 1.2,
  outline: 0,
  borderRadius: "md",
  _focusVisible: {
    boxShadow: "outline"
  }
};
var baseStyleLabel4 = {
  lineHeight: 1.2,
  overflow: "visible"
};
var baseStyleCloseButton4 = {
  fontSize: "18px",
  w: "1.25rem",
  h: "1.25rem",
  transitionProperty: "common",
  transitionDuration: "normal",
  borderRadius: "full",
  marginStart: "0.375rem",
  marginEnd: "-1",
  opacity: 0.5,
  _disabled: {
    opacity: 0.4
  },
  _focusVisible: {
    boxShadow: "outline",
    bg: "rgba(0, 0, 0, 0.14)"
  },
  _hover: { opacity: 0.8 },
  _active: { opacity: 1 }
};
var baseStyle38 = {
  container: baseStyleContainer5,
  label: baseStyleLabel4,
  closeButton: baseStyleCloseButton4
};
var sizes21 = {
  sm: {
    container: {
      minH: "1.25rem",
      minW: "1.25rem",
      fontSize: "xs",
      px: 2
    },
    closeButton: {
      marginEnd: "-2px",
      marginStart: "0.35rem"
    }
  },
  md: {
    container: {
      minH: "1.5rem",
      minW: "1.5rem",
      fontSize: "sm",
      px: 2
    }
  },
  lg: {
    container: {
      minH: 8,
      minW: 8,
      fontSize: "md",
      px: 3
    }
  }
};
var variants11 = {
  subtle: (props) => ({
    container: badge_default.variants.subtle(props)
  }),
  solid: (props) => ({
    container: badge_default.variants.solid(props)
  }),
  outline: (props) => ({
    container: badge_default.variants.outline(props)
  })
};
var defaultProps23 = {
  size: "md",
  variant: "subtle",
  colorScheme: "gray"
};
var tag_default = {
  parts: parts24.keys,
  variants: variants11,
  baseStyle: baseStyle38,
  sizes: sizes21,
  defaultProps: defaultProps23
};

// src/components/textarea.ts
var baseStyle39 = {
  ...input_default.baseStyle.field,
  paddingY: "8px",
  minHeight: "80px",
  lineHeight: "short",
  verticalAlign: "top"
};
var variants12 = {
  outline: (props) => input_default.variants.outline(props).field ?? {},
  flushed: (props) => input_default.variants.flushed(props).field ?? {},
  filled: (props) => input_default.variants.filled(props).field ?? {},
  unstyled: input_default.variants.unstyled.field ?? {}
};
var sizes22 = {
  xs: input_default.sizes.xs.field ?? {},
  sm: input_default.sizes.sm.field ?? {},
  md: input_default.sizes.md.field ?? {},
  lg: input_default.sizes.lg.field ?? {}
};
var defaultProps24 = {
  size: "md",
  variant: "outline"
};
var textarea_default = {
  baseStyle: baseStyle39,
  sizes: sizes22,
  variants: variants12,
  defaultProps: defaultProps24
};

// src/components/tooltip.ts
import { mode as mode24, cssVar as cssVar7 } from "@chakra-ui/theme-tools";
var $bg = cssVar7("tooltip-bg");
var $arrowBg2 = cssVar7("popper-arrow-bg");
var baseStyle40 = (props) => {
  const bg = mode24("gray.700", "gray.300")(props);
  return {
    [$bg.variable]: `colors.${bg}`,
    px: "8px",
    py: "2px",
    bg: [$bg.reference],
    [$arrowBg2.variable]: [$bg.reference],
    color: mode24("whiteAlpha.900", "gray.900")(props),
    borderRadius: "sm",
    fontWeight: "medium",
    fontSize: "sm",
    boxShadow: "md",
    maxW: "320px",
    zIndex: "tooltip"
  };
};
var tooltip_default = {
  baseStyle: baseStyle40
};

// src/components/index.ts
var components = {
  Accordion: accordion_default,
  Alert: alert_default,
  Avatar: avatar_default,
  Badge: badge_default,
  Breadcrumb: breadcrumb_default,
  Button: button_default,
  Checkbox: checkbox_default,
  CloseButton: close_button_default,
  Code: code_default,
  Container: container_default,
  Divider: divider_default,
  Drawer: drawer_default,
  Editable: editable_default,
  Form: form_default,
  FormError: form_error_default,
  FormLabel: form_label_default,
  Heading: heading_default,
  Input: input_default,
  Kbd: kbd_default,
  Link: link_default,
  List: list_default,
  Menu: menu_default,
  Modal: modal_default,
  NumberInput: number_input_default,
  PinInput: pin_input_default,
  Popover: popover_default,
  Progress: progress_default,
  Radio: radio_default,
  Select: select_default,
  Skeleton: skeleton_default,
  SkipLink: skip_link_default,
  Slider: slider_default,
  Spinner: spinner_default,
  Stat: stat_default,
  Switch: switch_default,
  Table: table_default,
  Tabs: tabs_default,
  Tag: tag_default,
  Textarea: textarea_default,
  Tooltip: tooltip_default
};

// src/foundations/borders.ts
var borders = {
  none: 0,
  "1px": "1px solid",
  "2px": "2px solid",
  "4px": "4px solid",
  "8px": "8px solid"
};
var borders_default = borders;

// src/foundations/breakpoints.ts
var breakpoints = {
  base: "0em",
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em"
};
var breakpoints_default = breakpoints;

// src/foundations/colors.ts
var colors = {
  transparent: "transparent",
  current: "currentColor",
  black: "#000000",
  white: "#FFFFFF",
  whiteAlpha: {
    50: "rgba(255, 255, 255, 0.04)",
    100: "rgba(255, 255, 255, 0.06)",
    200: "rgba(255, 255, 255, 0.08)",
    300: "rgba(255, 255, 255, 0.16)",
    400: "rgba(255, 255, 255, 0.24)",
    500: "rgba(255, 255, 255, 0.36)",
    600: "rgba(255, 255, 255, 0.48)",
    700: "rgba(255, 255, 255, 0.64)",
    800: "rgba(255, 255, 255, 0.80)",
    900: "rgba(255, 255, 255, 0.92)"
  },
  blackAlpha: {
    50: "rgba(0, 0, 0, 0.04)",
    100: "rgba(0, 0, 0, 0.06)",
    200: "rgba(0, 0, 0, 0.08)",
    300: "rgba(0, 0, 0, 0.16)",
    400: "rgba(0, 0, 0, 0.24)",
    500: "rgba(0, 0, 0, 0.36)",
    600: "rgba(0, 0, 0, 0.48)",
    700: "rgba(0, 0, 0, 0.64)",
    800: "rgba(0, 0, 0, 0.80)",
    900: "rgba(0, 0, 0, 0.92)"
  },
  gray: {
    50: "#F7FAFC",
    100: "#EDF2F7",
    200: "#E2E8F0",
    300: "#CBD5E0",
    400: "#A0AEC0",
    500: "#718096",
    600: "#4A5568",
    700: "#2D3748",
    800: "#1A202C",
    900: "#171923"
  },
  red: {
    50: "#FFF5F5",
    100: "#FED7D7",
    200: "#FEB2B2",
    300: "#FC8181",
    400: "#F56565",
    500: "#E53E3E",
    600: "#C53030",
    700: "#9B2C2C",
    800: "#822727",
    900: "#63171B"
  },
  orange: {
    50: "#FFFAF0",
    100: "#FEEBC8",
    200: "#FBD38D",
    300: "#F6AD55",
    400: "#ED8936",
    500: "#DD6B20",
    600: "#C05621",
    700: "#9C4221",
    800: "#7B341E",
    900: "#652B19"
  },
  yellow: {
    50: "#FFFFF0",
    100: "#FEFCBF",
    200: "#FAF089",
    300: "#F6E05E",
    400: "#ECC94B",
    500: "#D69E2E",
    600: "#B7791F",
    700: "#975A16",
    800: "#744210",
    900: "#5F370E"
  },
  green: {
    50: "#F0FFF4",
    100: "#C6F6D5",
    200: "#9AE6B4",
    300: "#68D391",
    400: "#48BB78",
    500: "#38A169",
    600: "#2F855A",
    700: "#276749",
    800: "#22543D",
    900: "#1C4532"
  },
  teal: {
    50: "#E6FFFA",
    100: "#B2F5EA",
    200: "#81E6D9",
    300: "#4FD1C5",
    400: "#38B2AC",
    500: "#319795",
    600: "#2C7A7B",
    700: "#285E61",
    800: "#234E52",
    900: "#1D4044"
  },
  blue: {
    50: "#ebf8ff",
    100: "#bee3f8",
    200: "#90cdf4",
    300: "#63b3ed",
    400: "#4299e1",
    500: "#3182ce",
    600: "#2b6cb0",
    700: "#2c5282",
    800: "#2a4365",
    900: "#1A365D"
  },
  cyan: {
    50: "#EDFDFD",
    100: "#C4F1F9",
    200: "#9DECF9",
    300: "#76E4F7",
    400: "#0BC5EA",
    500: "#00B5D8",
    600: "#00A3C4",
    700: "#0987A0",
    800: "#086F83",
    900: "#065666"
  },
  purple: {
    50: "#FAF5FF",
    100: "#E9D8FD",
    200: "#D6BCFA",
    300: "#B794F4",
    400: "#9F7AEA",
    500: "#805AD5",
    600: "#6B46C1",
    700: "#553C9A",
    800: "#44337A",
    900: "#322659"
  },
  pink: {
    50: "#FFF5F7",
    100: "#FED7E2",
    200: "#FBB6CE",
    300: "#F687B3",
    400: "#ED64A6",
    500: "#D53F8C",
    600: "#B83280",
    700: "#97266D",
    800: "#702459",
    900: "#521B41"
  },
  linkedin: {
    50: "#E8F4F9",
    100: "#CFEDFB",
    200: "#9BDAF3",
    300: "#68C7EC",
    400: "#34B3E4",
    500: "#00A0DC",
    600: "#008CC9",
    700: "#0077B5",
    800: "#005E93",
    900: "#004471"
  },
  facebook: {
    50: "#E8F4F9",
    100: "#D9DEE9",
    200: "#B7C2DA",
    300: "#6482C0",
    400: "#4267B2",
    500: "#385898",
    600: "#314E89",
    700: "#29487D",
    800: "#223B67",
    900: "#1E355B"
  },
  messenger: {
    50: "#D0E6FF",
    100: "#B9DAFF",
    200: "#A2CDFF",
    300: "#7AB8FF",
    400: "#2E90FF",
    500: "#0078FF",
    600: "#0063D1",
    700: "#0052AC",
    800: "#003C7E",
    900: "#002C5C"
  },
  whatsapp: {
    50: "#dffeec",
    100: "#b9f5d0",
    200: "#90edb3",
    300: "#65e495",
    400: "#3cdd78",
    500: "#22c35e",
    600: "#179848",
    700: "#0c6c33",
    800: "#01421c",
    900: "#001803"
  },
  twitter: {
    50: "#E5F4FD",
    100: "#C8E9FB",
    200: "#A8DCFA",
    300: "#83CDF7",
    400: "#57BBF5",
    500: "#1DA1F2",
    600: "#1A94DA",
    700: "#1681BF",
    800: "#136B9E",
    900: "#0D4D71"
  },
  telegram: {
    50: "#E3F2F9",
    100: "#C5E4F3",
    200: "#A2D4EC",
    300: "#7AC1E4",
    400: "#47A9DA",
    500: "#0088CC",
    600: "#007AB8",
    700: "#006BA1",
    800: "#005885",
    900: "#003F5E"
  }
};
var colors_default = colors;

// src/foundations/radius.ts
var radii = {
  none: "0",
  sm: "0.125rem",
  base: "0.25rem",
  md: "0.375rem",
  lg: "0.5rem",
  xl: "0.75rem",
  "2xl": "1rem",
  "3xl": "1.5rem",
  full: "9999px"
};
var radius_default = radii;

// src/foundations/shadows.ts
var shadows = {
  xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
  sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  base: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
  outline: "0 0 0 3px rgba(66, 153, 225, 0.6)",
  inner: "inset 0 2px 4px 0 rgba(0,0,0,0.06)",
  none: "none",
  "dark-lg": "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px"
};
var shadows_default = shadows;

// src/foundations/transition.ts
var transitionProperty = {
  common: "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform",
  colors: "background-color, border-color, color, fill, stroke",
  dimensions: "width, height",
  position: "left, right, top, bottom",
  background: "background-color, background-image, background-position"
};
var transitionTimingFunction = {
  "ease-in": "cubic-bezier(0.4, 0, 1, 1)",
  "ease-out": "cubic-bezier(0, 0, 0.2, 1)",
  "ease-in-out": "cubic-bezier(0.4, 0, 0.2, 1)"
};
var transitionDuration = {
  "ultra-fast": "50ms",
  faster: "100ms",
  fast: "150ms",
  normal: "200ms",
  slow: "300ms",
  slower: "400ms",
  "ultra-slow": "500ms"
};
var transition = {
  property: transitionProperty,
  easing: transitionTimingFunction,
  duration: transitionDuration
};
var transition_default = transition;

// src/foundations/z-index.ts
var zIndices = {
  hide: -1,
  auto: "auto",
  base: 0,
  docked: 10,
  dropdown: 1e3,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800
};
var z_index_default = zIndices;

// src/foundations/blur.ts
var blur = {
  none: 0,
  sm: "4px",
  base: "8px",
  md: "12px",
  lg: "16px",
  xl: "24px",
  "2xl": "40px",
  "3xl": "64px"
};
var blur_default = blur;

// src/foundations/index.ts
var foundations = {
  breakpoints: breakpoints_default,
  zIndices: z_index_default,
  radii: radius_default,
  blur: blur_default,
  colors: colors_default,
  ...typography_default,
  sizes: sizes_default,
  shadows: shadows_default,
  space: spacing,
  borders: borders_default,
  transition: transition_default
};

// src/semantic-tokens.ts
var semanticTokens = {
  colors: {
    "chakra-body-text": { _light: "gray.800", _dark: "whiteAlpha.900" },
    "chakra-body-bg": { _light: "white", _dark: "gray.800" },
    "chakra-border-color": { _light: "gray.200", _dark: "whiteAlpha.300" },
    "chakra-placeholder-color": { _light: "gray.500", _dark: "whiteAlpha.400" }
  }
};

// src/styles.ts
var styles = {
  global: {
    body: {
      fontFamily: "body",
      color: "chakra-body-text",
      bg: "chakra-body-bg",
      transitionProperty: "background-color",
      transitionDuration: "normal",
      lineHeight: "base"
    },
    "*::placeholder": {
      color: "chakra-placeholder-color"
    },
    "*, *::before, &::after": {
      borderColor: "chakra-border-color",
      wordWrap: "break-word"
    }
  }
};

// src/utils.ts
import { isObject } from "@chakra-ui/utils";
var requiredChakraThemeKeys = [
  "borders",
  "breakpoints",
  "colors",
  "components",
  "config",
  "direction",
  "fonts",
  "fontSizes",
  "fontWeights",
  "letterSpacings",
  "lineHeights",
  "radii",
  "shadows",
  "sizes",
  "space",
  "styles",
  "transition",
  "zIndices"
];
function isChakraTheme(unit) {
  if (!isObject(unit)) {
    return false;
  }
  return requiredChakraThemeKeys.every((propertyName) => Object.prototype.hasOwnProperty.call(unit, propertyName));
}

// src/index.ts
var direction = "ltr";
var config = {
  useSystemColorMode: false,
  initialColorMode: "light",
  cssVarPrefix: "chakra"
};
var theme = {
  semanticTokens,
  direction,
  ...foundations,
  components,
  styles,
  config
};
var src_default = theme;
export {
  src_default as default,
  isChakraTheme,
  requiredChakraThemeKeys,
  theme
};
